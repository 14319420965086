import {useParams} from "react-router-dom";
import {useNavigate} from "react-router";
import {useMutation, useQuery} from "react-query";
import http from "../../../Services/Network/http";
import {Formik} from "formik";
import * as Yup from "yup";
import {Button, Col, Form, FormGroup, Input, Label, Row} from "reactstrap";
import CheckboxTree from "react-checkbox-tree";
import React, {useEffect, useState} from "react";
import moment from "moment";

interface bulkAddCredit {
    credit: number
    costAccountIds: number[],
    periodYear: number,
    method: number
}

const getNodeIds = (nodes : any[]) => {
    let ids : string[] = [];

    nodes?.forEach(({ value, children } : (any)) => {
        ids = [...ids, value, ...getNodeIds(children)];
    });

    return ids;
};

export const AdminBulkAddCredit = () => {

    const [ expanded, setExpanded ] = useState<string[]>([]);
    const [ checked, setChecked ] = useState<string[]>([]);

    const navigate = useNavigate();

    const userMutation = useMutation((bulkCreditForSaving : bulkAddCredit) =>  http.post('/user/bulkAddCredit', bulkCreditForSaving), {
        onSuccess: (data) => {
            navigate("/admin/users");
        },
        onError: (error : any, variables, context) => {
            console.log(error.response.data);
        },
    });

    const { data: costAccounts } = useQuery(
        ["fetchFilteredCostAccounts"],
        () => http.get(`/CostAccount`))

    
    const [costAccountData, setCostAccountData] = useState([])
    useEffect(() => {
        setCostAccountData((costAccounts || []).sort((a : any, b : any) => a.costAccountNumber > b.costAccountNumber ? 1 : -1).filter(function (item : any) {
            return item.parentCostAccountId == null
        }).map((item : any) => {
                return {
                    value: item.costAccountId,
                    label: `${item.costAccountNumber} - ${item.costAccountName}`,
                    children: (item.costAccounts || []).length === 0 ? null : (item.costAccounts || []).sort((a : any, b : any) => a.costAccountNumber > b.costAccountNumber ? 1 : -1).map((item2 : any) => {
                        return {
                            value: item2.costAccountId,
                            label: `${item2.costAccountNumber} - ${item2.costAccountName}`,
                            children: (item2.costAccounts || []).length === 0 ? null : (item2.costAccounts || []).sort((a : any, b : any) => a.costAccountNumber > b.costAccountNumber ? 1 : -1).map((item3 : any) => {
                                return {
                                    value: item3.costAccountId,
                                    label: `${item3.costAccountNumber} - ${item3.costAccountName}`,
                                    children: (item3.costAccounts || []).length === 0 ? null : (item3.costAccounts || []).sort((a : any, b : any) => a.costAccountNumber > b.costAccountNumber ? 1 : -1).map((item4 : any) => {
                                        return {
                                            value: item4.costAccountId,
                                            label: `${item4.costAccountNumber} - ${item4.costAccountName}`,
                                        }
                                    })
                                }
                            })
                        }
                    })
                }
            }
        ));
    }, [costAccounts]);

    useEffect(() => {
        setExpanded(getNodeIds(costAccountData));
    }, [costAccounts, costAccountData]);
    
    const periodYears =  [moment().year(), moment().year() + 1];

    return (
        <div>
            <Formik
                enableReinitialize={true}
                initialValues={{
                    credit: 0,
                    costAccountIds: [],
                    periodYear: moment().year(),
                    method: 1,
                }}
                validationSchema={Yup.object({
                    credit: Yup.number().required("Kreditt er påkrevd"),
                    costAccountIds: Yup.array().required("Kostnadssted er påkrevd"),
                    periodYear: Yup.number().required("År er påkrevd"),
                    method: Yup.number().required("Metode er påkrevd")
                })}
                onSubmit={(values, {setSubmitting}) => {
                    setSubmitting(false);
                    userMutation.mutate({
                        credit: values.credit,
                        costAccountIds: checked.map(Number),
                        periodYear: values.periodYear,
                        method: values.method
                    });
                }}>
                {
                    ({
                         values,
                         errors,
                         touched,
                         handleChange,
                         handleBlur,
                         isSubmitting,
                         isValid,
                         handleSubmit
                     }) => (
                        <Form onSubmit={handleSubmit} noValidate>
                            <Row>
                                <Col md={12}>
                                    <FormGroup>
                                        <Label for="credit">
                                            Poeng
                                        </Label>
                                        <Input
                                            id="credit"
                                            name="credit"
                                            placeholder="Skriv inn poeng som skal legges til"
                                            invalid={touched.credit && !!errors.credit}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.credit}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="periodYear">Periode</Label>
                                        <Input
                                            type="select"
                                            name="periodYear"
                                            id="periodYear"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.periodYear}>
                                            {periodYears.map((periodYear) => <option
                                                value={periodYear}>{periodYear}</option>)}
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="method">Metode</Label>
                                        <Input
                                            type="select"
                                            name="method"
                                            id="method"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.method}>
                                            <option value={1}>Legg til</option>)
                                            <option value={2}>Sett total</option>)
                                        </Input>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <FormGroup>
                                        <Label for="description">
                                            Kostnadsgruppe
                                        </Label>
                                        <CheckboxTree
                                            nodes={costAccountData}
                                            checked={checked}
                                            showExpandAll={true}
                                            expanded={expanded}
                                            onCheck={nChecked =>  setChecked(nChecked)}
                                            onExpand={nExpanded => setExpanded(nExpanded)}
                                            onlyLeafCheckboxes={true}
                                            iconsClass="fa5"
                                        />
                                    </FormGroup>
                                    </Col>
                            </Row>
                            <Button type="submit" disabled={isSubmitting || !isValid || checked.length == 0}>
                                {isSubmitting ? 'Laster...' : 'Oppdater' }
                            </Button>
                            <br/><br/>
                        </Form>
                    )}
            </Formik>
        </div>
    )
}