import * as Yup from "yup";
import {Button, Col, Form, FormGroup, Input, Label, Row} from "reactstrap";
import {Formik} from "formik";
import {useMutation, useQuery} from "react-query";
import http from "../../../Services/Network/http";

import {useParams} from "react-router-dom";
import {useNavigate} from "react-router";

interface productVariant {
    productVariantId: number
    productId: number
    productNr: string
    variant: string
}

export const AdminProductVariant = () => {
    const { id, productVariantId } = useParams();

    const navigate = useNavigate();

    const productVariantMutation = useMutation((productVariantForSaving : productVariant) => productVariantForSaving.productVariantId === null ? http.post('/product/AddProductVariant', productVariantForSaving) : http.put('/product/UpdateProductVariant', productVariantForSaving), {
        onSuccess: (data) => {
            navigate(`/admin/Product/${id}/productVariants`);
        },
        onError: (error : any, variables, context) => {
            console.log(error.response.data);
        },
    });

    const { data: productVariant } = useQuery(
        ["fetchProductVariant", productVariantId],
        () => http.get(`/product/GetProductVariant/${productVariantId}`),
        {enabled: id !== undefined})

    return (
        <div>
            <Formik
                enableReinitialize={true}
                initialValues={{
                    productVariantId: productVariant?.productVariantId ?? null,
                    productNr: productVariant?.productNr ?? "",
                    variant: productVariant?.variant ?? "",
                }}
                validationSchema={Yup.object({
                    productNr: Yup.string().required(),
                    variant: Yup.string().required()
                })}
                onSubmit={(values, {setSubmitting}) => {
                    setSubmitting(false);
                    productVariantMutation.mutate({
                        productVariantId: values.productVariantId,
                        productId: parseInt(id!),
                        productNr: values.productNr,
                        variant: values.variant
                    });
                }}>
                {
                    ({
                         values,
                         errors,
                         touched,
                         handleChange,
                         handleBlur,
                         isSubmitting,
                         isValid,
                         handleSubmit
                     }) => (
                        <Form onSubmit={handleSubmit} noValidate>
                            <Row>
                                <Col md={12}>
                                    <FormGroup>
                                        <Label for="name">
                                            Product Nr
                                        </Label>
                                        <Input
                                            id="productNr"
                                            name="productNr"
                                            placeholder="Produkt nr"
                                            invalid={touched.productNr && !!errors.productNr}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.productNr}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={12}>
                                    <FormGroup>
                                        <Label for="variant">
                                            Variant
                                        </Label>
                                        <Input
                                            id="variant"
                                            name="variant"
                                            placeholder="Variant"
                                            invalid={touched.variant && !!errors.variant}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.variant}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Button type="submit" disabled={isSubmitting || !isValid}>
                                {isSubmitting ? 'Laster...' : 'Lagre'}
                            </Button>
                        </Form>
                    )}
            </Formik>
        </div>
    )
}