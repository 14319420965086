import {useQuery} from "react-query";
import http from "../../../Services/Network/http";
import {useParams} from "react-router-dom";
import {useNavigate} from "react-router";
import moment from "moment";
import DataTable from "react-data-table-component";


export const OrderApprovalList = () => {
        const {period} = useParams();
        const navigate = useNavigate();

        const { data: orders } = useQuery(
            "fetchOrdersForApproval",
            () => http.get(`/Order/ByStatus/4`));

        const columns = [
            {
                name: 'Ordre.Nr',
                selector: (row : any) => row.orderId,
                grow: 3
            },
            {
                name: 'Status',
                selector: (row : any) => row.orderStatus?.title,
                grow: 3
            },
            {
                name: 'Bestiller',
                selector: (row : any) => row.createdByUser?.fullName,
                grow: 3
            },
            {
                name: 'Opprettet',
                selector: (row : any) => moment(row.createdAt).format('DD.MM.yyyy HH:mm'),
                grow: 3
            }
        ];


        return (
            <div>
                <h2 style={{float:'left'}}>Kostkonto &gt; Ordrer til godkjenning</h2>
                <DataTable
                    highlightOnHover={true}
                    onRowClicked={(row) => navigate(`/account/leader/orderApproval/${row.orderId}`)}
                    columns={columns}
                    data={(orders || []).filter((order : any) => {
                        if(period === undefined)
                            return order
                        else if(new Date(order.createdAt).getFullYear() === parseInt(period ?? "")) 
                            return order
                        return []
                    })}
                />
            </div>
        );
    }