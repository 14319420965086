
export const AdminWelcomeMail = () => {

    return (
        <div>
            <p>Hei @Navn@,</p>
            <p>Velkommen til Widerøe Technical Services AS innkjøpsportal for arbeidsutstyr.</p>
            <p>Du finner portalen på @Nettsted@</p>
            <p>
                Innloggings info:<br/>
                For å logge inn bruker du ditt Widerøe ansatt nummer: @Ansattnr@ og passord: @Passord@<br/>
                <br/>
                Gjennom portalen tilbyr WTS arbeidsutstyr av høy kvalitet kombinert med flott design og funksjonalitet.<br/>
                <br/>
                Nødvendig innkjøp utover tildelte poengsum kan, etter behov, godkjennes av nærmeste leder i portalen.<br/>
                <br/>
                Etter bestilling blir arbeidsutstyret levert Nor Tekstil for merking og deretter levert til din kleskurv i mellomgangen bak kantinen.<br/>
                <br/>
                Rutine for retur/reklamasjon, er beskrevet under "ofte stilte spørsmål" fanen i portalen.<br/>
                <br/>
                Vennlig hilsen<br/>
                Widerøe Technical Services AS<br/>
            </p>
        </div>
    )
}