import React, {useContext} from "react";
import {Col, Container, FormGroup, Input, Label, Row} from "reactstrap";
import {ShoppingCartContext} from "../Context/ShoppingCartContext";
import {TiTrash} from "react-icons/ti";
import {Button} from "reactstrap";
import {useMutation} from "react-query";
import http from "../Services/Network/http";
import {useNavigate} from "react-router";
import {toast} from "react-toastify";
import * as Yup from "yup";
import {Form, Formik} from "formik";
import {useTranslation} from "react-i18next";

interface order {
    notes: string
    orderItems: orderItems[]
}

interface orderItems {
    productId: number
    productVariantId: number
    quantity: number
}

export const Checkout = () => {
    const shoppingCartContext = useContext(ShoppingCartContext);
    const navigate = useNavigate();

    const [t] = useTranslation('base');
    
    const checkoutMutation = useMutation((orderForSaving : order) => http.post('/Checkout', orderForSaving), {
        onSuccess: (data) => {
            shoppingCartContext.dispatch({type: "clear"})
            toast("Din ordre ble opprettet, du kan se status på ordrehistorikk.", {type: "success"});
            navigate("/account/orders");
        },
        onError: (error : any, variables, context) => {
            console.log(error.response.data);
        },
    });
    
    if(shoppingCartContext.state.length === 0) {
        return <div>{t('checkout.noContent')}</div>
    }

    
    return (
        <div>
            <Container>
                <h2>{t('checkout.checkout')}</h2>
                {shoppingCartContext.state.map((item, index) => (
                    <Row key={index}>
                        <Col md={2}>
                            <img alt={"product"} src={`${item.imageUrl}`} width={"100%"} style={{border: "1px solid lightgray", borderRadius: "0.25rem"}} />
                        </Col>
                        <Col md={8}>
                            <b>{item.title} - {item.variant}</b><br/>
                            {t('checkout.quantity')}: {item.quantity}<br/>
                            {t("checkout.price")}: {item.price}
                        </Col>
                        <Col md={2}>
                            <TiTrash onClick={() => shoppingCartContext.dispatch({ type: 'remove', item})} style={{cursor: 'pointer', width: 24, height: 24, float:'right' }} />
                        </Col>
                        <Col md={12}><hr/></Col>
                    </Row>
                ))}
                <Formik
                    enableReinitialize={true}
                    initialValues={{
                        notes: "",
                    }}
                    validationSchema={Yup.object({
                        notes: Yup.string().optional(),
                    })}
                    onSubmit={(values, {setSubmitting}) => {
                        setSubmitting(true);
                        checkoutMutation.mutate({
                            notes: values.notes,
                            orderItems: shoppingCartContext.state.map((item, index) => {
                                return {
                                    productId: item.productId,
                                    productVariantId: item.productVariantId,
                                    quantity: item.quantity
                                }
                            })
                        }, { onSettled : () => setSubmitting(false) });
                    }}>
                    {
                        ({
                             values,
                             errors,
                             touched,
                             handleChange,
                             handleBlur,
                             isSubmitting,
                             isValid,
                             handleSubmit
                         }) => (
                            <Form onSubmit={handleSubmit} noValidate>
                                <Row>
                                    <Col md={12}>
                                        <FormGroup>
                                            <Label for="notes">
                                                {t('checkout.notes')}
                                            </Label>
                                            <Input
                                                id="notes"
                                                name="notes"
                                                placeholder={t('checkout.notes')}
                                                invalid={touched.notes && !!errors.notes}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.notes}
                                                type="textarea"
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Button type="submit" disabled={isSubmitting || !isValid}>
                                {isSubmitting ? 'Laster...' : t('checkout.purchase')}
                            </Button>
                            </Form>
                        )}
                </Formik>
            </Container>
        </div>
    );
}