import {useMutation, useQuery, useQueryClient} from "react-query";

import moment from "moment";
import DataTable from "react-data-table-component";
import {useParams} from "react-router-dom";
import {Button} from "reactstrap";
import http from "../../../Services/Network/http";
import {BsCashCoin} from "react-icons/bs";

export const AdminOrder = () => {
    const queryClient = useQueryClient()
    const { id } = useParams();

    const { data: order } = useQuery(
        ["fetchOrder", id],
        () => http.get(`/Order/${id}`));

    const columns = [
        {
            name: 'Vare.Nr',
            selector: (row : any) => row.productNr,
            grow: 3
        },
        {
            name: 'Tittel',
            selector: (row : any) => row.title,
            grow: 5
        },
        {
            name: 'Variant',
            selector: (row : any) => row.variant,
            grow: 3
        },
        {
            name: 'Pris',
            selector: (row : any) => row.price,
            grow: 3
        },
        {
            name: 'Antall',
            selector: (row : any) => row.quantity,
            grow: 3
        },
        {
            name: 'Ant. 365 dager',
            selector: (row : any) => row.purchasesPast365Days,
            grow: 3
        }
    ];

    const orderMutation = useMutation((orderStatusId : number) =>  http.put(`/order/UpdateOrderStatus/${id}/${orderStatusId}`), {
        onSuccess: (data) => {
            queryClient.invalidateQueries("fetchOrder");
        },
        onError: (error : any, variables, context) => {
            console.log(error.response.data);
        },
    });


    return (
        <div>
            <h2 style={{float:'left'}}>Admin &gt; Ordreliste &gt; Ordre</h2>
            
            <Button color="primary" style={{float: 'right'}} size="sm" onClick={() => orderMutation.mutate(2)}>Behandlet</Button>
            <Button color="warning" style={{float: 'right', marginRight: 5}} size="sm" onClick={() => orderMutation.mutate(4)}>Til godkjenning</Button>
            <Button color="info" style={{float: 'right', marginRight: 5}} size="sm" onClick={() => orderMutation.mutate(1)}>Til behandling</Button>
            <Button color="danger"  style={{float:'right', marginRight: 5}} size="sm" onClick={() => window.confirm("Er du sikker på at du vil kansellere ordre?") ? orderMutation.mutate(3) : null}>Kansellert</Button>
        
            
            <div style={{clear:"both"}}/>
            <hr/>
            <p>
                <b>OrdreId:</b><br/>
                {order?.orderId}
            </p>
            <p>
                <b>Leverandør:</b><br/>
                {order?.supplier?.name}
            </p>
            <p>
                <b>Bestiller person:</b><br/>
                #{order?.createdByUser?.username}<br/>
                {order?.createdByUser?.fullName}<br/>
                {order?.createdByUser?.email}<br/>
                {order?.createdByUser?.cellPhone}<br/>
                <BsCashCoin /> {order?.createdByUser?.remainingCredit}
            </p>
            <p>
                <b>Bestiller lokasjon:</b><br/>
                {order?.createdByUser?.costAccount?.costAccountNumber} <br/>
                {order?.createdByUser?.costAccount?.costAccountName} <br/>
                {order?.createdByUser?.costAccount?.costAccountLocation}
            </p>
            <p>
                <b>Ordre total:</b><br/>
                {order?.orderItems.reduce((total : number, {price, quantity} : any) => {
                    return total + price * quantity;
                }, 0)}
            </p>
            <p>
                <b>Ordre status:</b><br/>
                {order?.orderStatus?.title}
            </p>
            <p>
                <b>Dato:</b><br/>
                {moment(order?.createdAt).format("DD.MM.yyyy HH:mm")}
            </p>
            <p>
                <b>Notat:</b><br/>
                {order?.notes === "" ? "--" : order?.notes}
            </p>
            <p>
                <b>Notat leverandør:</b><br/>
                {order?.supplierNotes === null ? "--" : order?.supplierNotes}
            </p>
            <hr/>
            <DataTable
                highlightOnHover={true}
                columns={columns}
                data={(order?.orderItems || [])}
            />
        </div>
    );
}