import * as Yup from "yup";
import {Button, Col, Form, FormGroup, Input, Label, Row} from "reactstrap";
import {Formik} from "formik";
import {useMutation, useQuery} from "react-query";
import http from "../../../Services/Network/http";

import 'react-quill/dist/quill.snow.css';

import { FilePond, registerPlugin } from 'react-filepond'

import 'filepond/dist/filepond.min.css'

import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'
import {useEffect, useState} from "react";
import config from "../../../config";
import LocalStorageService from "../../../Services/Storage/LocalStorageService";
import {useParams} from "react-router-dom";
import {useNavigate} from "react-router";

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview, FilePondPluginFileValidateType)

let pond;
export const AdminDocument = () => {
    const { id } = useParams();


    const navigate = useNavigate();

    const documentMutation = useMutation((documentForSaving) => documentForSaving.documentId === null ? http.post('/document', documentForSaving) : http.put('/document', documentForSaving), {
        onSuccess: () => {
            navigate("/Admin/documents");
        },
        onError: (error) => {
            console.log(error.response.data);
        },
    });

    const [files, setFiles] = useState([])

    const { data: document } = useQuery(
        ["fetchDocument", id],
        () => http.get(`/Document/${id}`),
        {enabled: id !== undefined})

    useEffect(() => {
        if(document?.documentFile !== undefined) {
            setFiles((files) => [...files, {
                source: document?.documentFile.viewToken,
                options: {
                    type: "local"
                }}])

        }
    }, [document])

    return (
        <div>
            <Formik
                enableReinitialize={true}
                initialValues={{
                    documentId: document?.documentId ?? null,
                    title: document?.title ?? "",
                    showInMenu: document?.showInMenu ?? true,
                    active: document?.active ?? true,
                }}
                validationSchema={Yup.object({
                    title: Yup.string().required(),
                    active: Yup.bool().required(),
                    showInMenu: Yup.bool().required(),
                })}
                onSubmit={(values, {setSubmitting}) => {
                    setSubmitting(false);
                    console.log(values);
                    documentMutation.mutate({
                        documentId: values.documentId,
                        title: values.title,
                        active: values.active ? 1 : 0,
                        showInMenu: values.showInMenu ? 1 : 0,
                        tempFiles: files.map((item) => item.serverId)
                    });
                }}>
                {
                    ({
                         values,
                         errors,
                         touched,
                         handleChange,
                         handleBlur,
                         isSubmitting,
                         isValid,
                         handleSubmit
                     }) => (
                        <Form onSubmit={handleSubmit} noValidate>
                            <Row>
                                <Col md={8}>
                                    <FormGroup>
                                        <Label for="title">
                                            Dokument navn
                                        </Label>
                                        <Input
                                            id="title"
                                            name="title"
                                            placeholder="Dokument navn"
                                            invalid={touched.title && !!errors.title}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.title}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <FormGroup check>
                                        <Label check for="showInMenu">
                                            <Input
                                                name="showInMenu"
                                                id="showInMenu"
                                                type="checkbox"
                                                invalid={touched.showInMenu && !!errors.showInMenu}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                checked={values.showInMenu}
                                            />
                                            Vis i menu?
                                        </Label>
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup check>
                                        <Label check for="active">
                                            <Input
                                                name="active"
                                                id="active"
                                                type="checkbox"
                                                invalid={touched.active && !!errors.active}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                checked={values.active}
                                            />
                                            Aktiv?
                                        </Label>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <br/>
                            <Row>
                                <Col md={12}>
                                    <FilePond
                                        ref={pond}
                                        files={files}
                                        onupdatefiles={setFiles}
                                        allowMultiple={false}
                                        maxFiles={1}
                                        server={
                                            {
                                                url: config.api.url,
                                                headers: {'Authorization': `Bearer ${LocalStorageService.getAccessToken()}`, 'Access-Control-Allow-Origin': '*'},
                                                process: {
                                                    url: 'file/Upload',
                                                    method: 'POST',
                                                    withCredentials: false,
                                                    timeout: 7000,
                                                },
                                                load: 'file/',
                                                revert: null,
                                                remove: null,
                                            }}
                                        name="filepond"
                                        acceptedFileTypes={['application/pdf']}
                                        labelIdle='Dra & slipp pdf dokumentene dine eller <span class="filepond--label-action">Bla gjennom</span>'
                                    />
                                </Col>
                            </Row>
                            <Button type="submit" disabled={isSubmitting || !isValid || files.length === 0}>
                                {isSubmitting ? 'Laster...' : 'Lagre'}
                            </Button>
                        </Form>
                    )}
            </Formik>
        </div>
    )
}