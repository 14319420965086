import { axiosInstance } from "./axios";

const get = async (url, urlParams) => {
    const { data } = await axiosInstance.get(url, 
        { 
            params: urlParams 
        });
    return data;
}

const post = async (url, params) => {
    const { data } = await axiosInstance.post(url, params);
    return data;
}

const put = async (url, params) => {
    const { data } = await axiosInstance.put(url, params);
    return data;
}

const patch = async (url, params) => {
    const { data } = await axiosInstance.patch(url, params);
    return data;
}

const remove = async (url) => {
    const { data } = await axiosInstance.delete(url);
    return data;
}

const http = {get, post, put, patch, remove}

export default http