import {useMutation, useQuery, useQueryClient} from "react-query";
import http from "../../../Services/Network/http";
import DataTable from "react-data-table-component";
import {BsFillTrashFill, BsGrid3X3Gap} from "react-icons/bs";
import {Button} from "reactstrap";
import {useNavigate} from "react-router";

export const AdminProductList = () => {
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const deleteProductMutation = useMutation(productId => http.remove(`/product/DeleteProduct/${productId}`), {
        onSuccess: () => {
            queryClient.invalidateQueries(["fetchProducts"]);
        },
        onError: (error) => {
            console.log(error.response.data);
        },
    });
    
    const { data: products } = useQuery(
        "fetchProducts",
        () => http.get(`/Product/GetProducts?filtered=false`));

    const columns = [
        {
            name: 'Tittel',
            selector: row => row.title,
            grow: 3
        },
        {
            name: 'Leverandør',
            selector: row => row.supplier?.name,
        },

        {
            name: 'Pris',
            selector: row => row.price,
        },
        {
            name: 'Aktiv',
            selector: row => row.active ? "Ja" : "Nei",
        },
        {

            cell: (row) => (
                <>
                    <BsGrid3X3Gap cursor="Pointer" onClick={() => navigate(`/admin/product/${row.productId}/productVariants`)} />&nbsp;&nbsp;
                    <BsFillTrashFill cursor="Pointer" onClick={() => window.confirm("Er du sikker på at du vil slette dette produktet?") ? deleteProductMutation.mutate(row.productId) : ""} />
                </>),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        }
    ];


    return (
        <div>
            <h2 style={{float:'left'}}>Admin &gt; Produkter</h2>
            <Button color="primary"  style={{float:'right'}} size="sm" onClick={() => navigate("/Admin/Product")}>Legg til nytt produkt</Button>
            <DataTable
                highlightOnHover={true}
                onRowClicked={(row) => navigate(`/admin/product/${row.productId}`)}
                columns={columns}
                data={(products || [])}
            />


        </div>
    );
}