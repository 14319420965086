import {useMutation, useQuery, useQueryClient} from "react-query";
import http from "../../Services/Network/http";
import moment from "moment";
import DataTable from "react-data-table-component";
import {useParams} from "react-router-dom";
import {Button, Col, Form, FormGroup, Input, Label, Row} from "reactstrap";
import * as Yup from "yup";
import {Formik} from "formik";
import React, {useRef} from "react";
import {toast} from "react-toastify";
import {useReactToPrint} from "react-to-print";

import {FaPrint} from "react-icons/fa";


const OrderContent = React.forwardRef((props, ref) =>{

    const { id } = useParams();
    const queryClient = useQueryClient();
    const { data: order } = useQuery(
        ["fetchOrder", id],
        () => http.get(`/Order/${id}`));

    const columns = [
        {
            name: 'Vare.Nr',
            selector: (row : any) => row.productNr,
            grow: 3
        },
        {
            name: 'Tittel',
            selector: (row : any) => row.title,
            grow: 5
        },
        {
            name: 'Variant',
            selector: (row : any) => row.variant,
            grow: 3
        },
        {
            name: 'Pris',
            selector: (row : any) => row.price,
            grow: 3
        },
        {
            name: 'Antall',
            selector: (row : any) => row.quantity,
            grow: 3
        }
    ];

    

    interface supplierNotes {
        supplierNotes: string
    }

    const orderSupplierNotesMutation = useMutation((supplierNotes : supplierNotes) =>  http.patch(`/order/UpdateOrderSupplierNotes/${id}`, supplierNotes), {
        onSuccess: (data) => {
            queryClient.invalidateQueries("fetchOrder");
            toast("Notes saved successfully.", { type: "success" });
        },
        onError: (error : any, variables, context) => {
            console.log(error.response.data);
        },
    });

    return (
        <div>
            <p>
                <b>OrdreId:</b><br/>
                {order?.orderId}
            </p>
            <p>
                <b>Bestiller person:</b><br/>
                #{order?.createdByUser?.username}<br/>
                {order?.createdByUser?.fullName}<br/>
                {order?.createdByUser?.email}<br/>
                {order?.createdByUser?.cellPhone}<br/>
            </p>
            <p>
                <b>Bestiller lokasjon:</b><br/>
                {order?.createdByUser?.costAccount?.costAccountNumber} <br/>
                {order?.createdByUser?.costAccount?.costAccountName} <br/>
                {order?.createdByUser?.costAccount?.costAccountLocation}
            </p>
            <p>
                <b>Ordre total:</b><br/>
                {order?.orderItems.reduce((total : number, {price, quantity} : any) => {
                    return total + price * quantity;
                }, 0)}
            </p>
            <p>
                <b>Ordre status:</b><br/>
                {order?.orderStatus?.title}
            </p>
            <p>
                <b>Dato:</b><br/>
                {moment(order?.createdAt).format("DD.MM.yyyy HH:mm")}
            </p>
            <p>
                <b>Notat:</b><br/>
                {order?.notes === "" ? "--" : order?.notes}
            </p>

            <Formik
                enableReinitialize={true}
                initialValues={{
                    supplierNotes: order?.supplierNotes ?? null,
                }}
                validationSchema={Yup.object({
                    supplierNotes: Yup.string().required(),
                })}
                onSubmit={(values, {setSubmitting}) => {
                    setSubmitting(false);
                    orderSupplierNotesMutation.mutate({
                        supplierNotes: values.supplierNotes
                    });

                }}>
                {
                    ({
                         values,
                         errors,
                         touched,
                         handleChange,
                         handleBlur,
                         isSubmitting,
                         isValid,
                         handleSubmit,
                         setFieldValue
                     }) => (
                        <Form onSubmit={handleSubmit} noValidate>
                            <Row>
                                <Col md={12}>
                                    <FormGroup>
                                        <Label for="supplierNotes">
                                            Leverandør kommentar:
                                        </Label>
                                        <Input
                                            type="textarea"
                                            id="supplierNotes"
                                            name="supplierNotes"
                                            placeholder="Leverandør kommentar"
                                            invalid={touched.supplierNotes && !!errors.supplierNotes}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.supplierNotes}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Button type="submit" disabled={isSubmitting || !isValid}>
                                {isSubmitting ? 'Laster...' : 'Lagre'}
                            </Button>
                        </Form>
                    )}
            </Formik>
            <hr/>
            <DataTable
                highlightOnHover={true}
                columns={columns}
                data={(order?.orderItems || [])}
            />
        </div>
    );
})



export const SupplierOrder = () => {
    const { id } = useParams();
    const queryClient = useQueryClient();
    const componentRef = useRef<HTMLDivElement>(null);
    const handlePrint = useReactToPrint({
        pageStyle: `
        @page {
            margin-top: 60px;
        }
        `,
        content: () => componentRef.current,
    });
    
    const orderMutation = useMutation((orderStatusId : number) =>  http.put(`/order/UpdateOrderStatus/${id}/${orderStatusId}`), {
        onSuccess: (data) => {
            queryClient.invalidateQueries("fetchOrder");
        },
        onError: (error : any, variables, context) => {
            console.log(error.response.data);
        },
    });

    const { data: order } = useQuery(
        ["fetchOrder", id],
        () => http.get(`/Order/${id}`));
    
    return (
        <>
            <h2 style={{float:'left'}}>Leverandør &gt; Ordreliste &gt; Ordre</h2>
            <FaPrint onClick={handlePrint} size={30} style={{float:'right',cursor:'pointer', marginLeft: 10}}/>
            {order?.orderStatusId === 1 &&
                <>
                    <Button color="primary" style={{float: 'right'}} size="sm" onClick={() => orderMutation.mutate(2)}>Sett behandlet</Button>
                    <Button color="danger"  style={{float:'right', marginRight: 5}} size="sm" onClick={() => window.confirm("Er du sikker på at du vil kansellere ordre?") ? orderMutation.mutate(3) : null}>Sett kansellert</Button>
                </>
            }

            <div style={{clear:"both"}}/>
            <hr/>
            <div  ref={componentRef}>
                <OrderContent />
            </div>
        </>
    )
   
}