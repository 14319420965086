import * as Yup from "yup";
import {Button, Col, Form, FormGroup, Input, Label, Row} from "reactstrap";
import {Formik} from "formik";
import {useMutation, useQuery} from "react-query";
import http from "../../../Services/Network/http";

import {useParams} from "react-router-dom";
import {useNavigate} from "react-router";
import CheckboxTree from "react-checkbox-tree";
import React, {useEffect, useState} from "react";

interface costAccount {
    costAccountId: number
    costAccountNumber: number
    costAccountName: string
    costAccountLocation: string
    responsibleUserId: number
    parentCostAccountId: number | null
}
const getNodeIds = (nodes : any) => {
    let ids : any = [];

    nodes?.forEach(({ value, children } : any) => {
        ids = [...ids, value, ...getNodeIds(children)];
    });

    return ids;
};

export const AdminCostAccount = () => {
    const { id } = useParams();

    const [ expanded, setExpanded ] = useState<string[]>([]);
    const [ checked, setChecked ] = useState<string[]>([]);
    
    const navigate = useNavigate();

    const costAccountMutation = useMutation((costAccountForSaving : costAccount) => costAccountForSaving.costAccountId === null ? http.post('/CostAccount', costAccountForSaving) : http.put('/CostAccount', costAccountForSaving), {
        onSuccess: (data) => {
            navigate("/admin/costAccounts");
        },
        onError: (error : any, variables, context) => {
            console.log(error.response.data);
        },
    });

    const { data: costAccount } = useQuery(
        ["fetchCostAccount", id],
        () => http.get(`/CostAccount/${id}`),
        {enabled: id !== undefined})

    const { data: users } = useQuery(
        ["fetchUsers"],
        () => http.get(`/User`))

    const { data: costAccounts } = useQuery(
        ["fetchCostAccountsAll"],
        () => http.get(`/CostAccount?filtered=false`))

    const costAccountData = (costAccounts || []).sort((a : any, b : any) => a.costAccountNumber > b.costAccountNumber ? 1 : -1).filter(function (item : any) {
        return item.parentCostAccountId == null
    }).map((item : any) => {
            return {
                value: item.costAccountId,
                label: `${item.costAccountNumber} - ${item.costAccountName}`,
                children: (item.costAccounts || []).length === 0 ? null : (item.costAccounts || []).sort((a : any, b : any) => a.costAccountNumber > b.costAccountNumber ? 1 : -1).map((item2 : any) => {
                    return {
                        value: item2.costAccountId,
                        label: `${item2.costAccountNumber} - ${item2.costAccountName}`,
                        children: (item2.costAccounts || []).length === 0 ? null : (item2.costAccounts || []).sort((a : any, b : any) => a.costAccountNumber > b.costAccountNumber ? 1 : -1).map((item3 : any) => {
                            return {
                                value: item3.costAccountId,
                                label: `${item3.costAccountNumber} - ${item3.costAccountName}`,
                                children: (item3.costAccounts || []).length === 0 ? null : (item3.costAccounts || []).sort((a : any, b : any) => a.costAccountNumber > b.costAccountNumber ? 1 : -1).map((item4 : any) => {
                                    return {
                                        value: item4.costAccountId,
                                        label: `${item4.costAccountNumber} - ${item4.costAccountName}`,
                                        showCheckbox: false
                                    }
                                })
                            }
                        })
                    }
                })
            }
        }
    );
    
    useEffect(() => {
        setExpanded(getNodeIds(costAccountData));
    }, [costAccounts]);

    useEffect(() => {
        if(costAccount?.parentCostAccountId !== null && costAccount?.parentCostAccountId !== undefined) {
            setChecked([costAccount.parentCostAccountId]);
        }
    }, [costAccount])
    
    return (
        <div>
            <Formik
                enableReinitialize={true}
                initialValues={{
                    costAccountId: costAccount?.costAccountId ?? null,
                    costAccountNumber: costAccount?.costAccountNumber ?? null,
                    costAccountName: costAccount?.costAccountName ?? "",
                    costAccountLocation: costAccount?.costAccountLocation ?? "",
                    responsibleUserId: costAccount?.responsibleUserId ?? null,
                    parentCostAccountId: costAccount?.parentCostAccountId ?? null
                }}
                validationSchema={Yup.object({
                    costAccountNumber: Yup.number().required(),
                    costAccountName: Yup.string().required(),
                    costAccountLocation: Yup.string().required(),
                    responsibleUserId: Yup.string().required()
                })}
                onSubmit={(values, {setSubmitting}) => {
                    setSubmitting(false);
                    costAccountMutation.mutate({
                        costAccountId: values.costAccountId,
                        costAccountNumber: values.costAccountNumber,
                        costAccountName: values.costAccountName,
                        costAccountLocation: values.costAccountLocation,
                        responsibleUserId: parseInt(values.responsibleUserId),
                        parentCostAccountId: checked.length > 0 ? parseInt(checked[0]) : null
                    });
                }}>
                {
                    ({
                         values,
                         errors,
                         touched,
                         handleChange,
                         handleBlur,
                         isSubmitting,
                         isValid,
                         handleSubmit
                     }) => (
                        <Form onSubmit={handleSubmit} noValidate>
                            <Row>
                                <Col md={4}>
                                    <FormGroup>
                                        <Label for="costAccountNumber">
                                            Kost.Nr
                                        </Label>
                                        <Input
                                            id="costAccountNumber"
                                            name="costAccountNumber"
                                            placeholder="Kost.Nr"
                                            invalid={touched.costAccountNumber && !!errors.costAccountNumber}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.costAccountNumber}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={8}>
                                    <FormGroup>
                                        <Label for="costAccountName">
                                            Kost.Navn
                                        </Label>
                                        <Input
                                            id="costAccountName"
                                            name="costAccountName"
                                            placeholder="Kost.Navn"
                                            invalid={touched.costAccountName && !!errors.costAccountName}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.costAccountName}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="costAccountLocation">
                                            Kost.Sted
                                        </Label>
                                        <Input
                                            id="costAccountLocation"
                                            name="costAccountLocation"
                                            placeholder="Kost.Sted"
                                            invalid={touched.costAccountLocation && !!errors.costAccountLocation}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.costAccountLocation}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="responsibleUserId">Ansvarlig</Label>
                                        <Input
                                            type="select"
                                            name="responsibleUserId"
                                            id="responsibleUserId"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.responsibleUserId}>
                                            <option value="">-- Velg en --</option>
                                            {(users || []).map((item : any, index : number) => (<option key={index} value={item.userId}>{item.fullName}</option>))}

                                        </Input>
                                    </FormGroup>
                                </Col>
                                
                                <Col md={12}>
                                    <FormGroup>
                                        <Label for="description">
                                            Relasjon
                                        </Label>
                                        <CheckboxTree
                                            nodes={costAccountData}
                                            checked={checked}
                                            showExpandAll={true}
                                            expanded={expanded}
                                            onCheck={(nChecked, targetNode)  =>  {setChecked([targetNode.value]); console.log(targetNode)}}
                                            onExpand={(nExpanded)  => setExpanded(nExpanded)}
                                            onlyLeafCheckboxes={false}
                                            noCascade={true}
                                            iconsClass="fa5"
                                        />
                                    </FormGroup>
                                </Col>
                                
                            </Row>
                            <Button type="submit" disabled={isSubmitting || !isValid}>
                                {isSubmitting ? 'Laster...' : 'Lagre'}
                            </Button><br/><br/>
                        </Form>
                    )}
            </Formik>
        </div>
    )
}