import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Form,
    FormGroup,
    Input,
    Label,
    Row
} from "reactstrap";
import {Formik} from "formik";
import * as Yup from "yup";
import {useMutation} from "react-query";
import LocalStorageService from "../Services/Storage/LocalStorageService";
import http from "../Services/Network/http";
import {useNavigate} from "react-router";
import {Link} from "@mui/material";
import {toast} from "react-toastify";
import {useParams} from "react-router-dom";

export const Forgot = () => {
    let navigate = useNavigate();
    const forgotMutation = useMutation(requestObjects => http.post('/Authorization/ForgotPassword?email='+encodeURIComponent(requestObjects.email)), {
        onSuccess: (data) => {
            navigate("/auth/login");
            toast("Password reset link has been sent to your email.", { type: "success" });
        },
        onError: (error) => {
            console.log(error.response.data);
        },
    });
    
    return (
        <Container>
            <Row>
            <Col md={3}><></></Col>
            <Col md={6}>
                <Card>
                    <CardHeader>
                        Glemt passord
                    </CardHeader>
                    <CardBody>
                    
                        <Formik
                            initialValues={{
                                email: ""
                            }}
                            validationSchema={Yup.object({
                                email: Yup.string().email().required()
                            })}
                            onSubmit={(values, {setSubmitting}) => {
                                setSubmitting(false);
                                forgotMutation.mutate({
                                    email: values.email
                                });
                            }}>
                            {
                                ({
                                     values,
                                     errors,
                                     touched,
                                     handleChange,
                                     handleBlur,
                                     isSubmitting,
                                     isValid,
                                     handleSubmit
                                 }) => (
                                    <Form onSubmit={handleSubmit} noValidate>
                                        <FormGroup>
                                            <Label for="email">
                                                Epost
                                            </Label>
                                            <Input
                                                autoComplete="email"
                                                id="email"
                                                name="email"
                                                placeholder="Epost"
                                                invalid={touched.email && !!errors.email}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.email}
                                            />
                                        </FormGroup>
                                        <Link style={{float: "right", cursor: 'pointer'}} onClick={() => navigate("/auth/login")}>Gå tilbake</Link>
                                        <Button type="submit" disabled={isSubmitting || !isValid}>
                                            {isSubmitting ? 'Laster...' : 'Send forespørsel'}
                                        </Button>
                                    </Form>
                                )}
                        </Formik>
                    </CardBody>
                </Card>
            </Col>
            <Col md={3}><></></Col>
            </Row>
        </Container>
    );
}