import React, {createContext, useReducer} from "react";

interface CartItem {
    productId: number
    productVariantId: number
    title: string
    supplierName: string
    variant: string
    shortDescription: string
    imageUrl: string
    price: number
    quantity: number
}

export const ShoppingCartContext = createContext<{state: CartItem[], dispatch: React.Dispatch<any>}>(null!);

export default function ShoppingCartReducer(state:CartItem[], action:any) {
    console.log(action, state);
    switch (action.type) {
        case 'add':
            const cartItemIndex = state.findIndex(item => item.productVariantId === action.item.productVariantId);
            
            if(cartItemIndex > -1) {
                state[cartItemIndex].quantity++;
                return [...state]
            }
            return [...state, {...action.item, quantity: 1}];
        case 'remove':
            const cartItemIndexToRemove = state.findIndex(item => item.productVariantId === action.item.productVariantId);
            if(cartItemIndexToRemove > -1) {
                state[cartItemIndexToRemove].quantity--;
                if(state[cartItemIndexToRemove].quantity === 0) {
                    return [...state.slice(0, cartItemIndexToRemove), ...state.slice(cartItemIndexToRemove + 1)];
                }
                return [...state];
            }
            return [...state];
        
        case 'clear': 
            return [];
        default:
            throw new Error();
    }
}

export const ShoppingCartContextProvider : React.FC = ({ children }) => {
    const [state, dispatch] = useReducer(ShoppingCartReducer, []);
    
    return (
        <ShoppingCartContext.Provider value={{state,dispatch}}>
            {children}
        </ShoppingCartContext.Provider>
    )
}