import {useNavigate} from "react-router";
import {useQuery} from "react-query";
import DataTable from "react-data-table-component";
import moment from "moment";
import {useParams} from "react-router-dom";
import http from "../../../Services/Network/http";

export const AdminOrders = () => {
    const {period} = useParams();
    const navigate = useNavigate();

    const { data: orders } = useQuery(
        "fetchOrders",
        () => http.get(`/Order`));

    const columns = [
        {
            name: 'Ordre.Nr',
            selector: (row : any) => row.orderId,
            sortable: true,
            grow: 3
        },
        {
            name: 'Status',
            selector: (row : any) => row.orderStatus?.title,
            sortable: true,
            grow: 3
        },
        {
            name: 'Bestiller',
            selector: (row : any) => row.createdByUser?.fullName,
            sortable: true,
            grow: 3
        },
        {
            name: 'Leverandør',
            selector: (row : any) => row.supplier?.name,
            sortable: true,
            grow: 3
        },
        {
            name: 'Opprettet',
            selector: (row : any) => moment(row.createdAt).format('DD.MM.yyyy HH:mm'),
            sortable: true,
            grow: 3
        }
    ];


    return (
        <div>
            <h2 style={{float:'left'}}>Admin &gt; Ordreliste</h2>
            <DataTable
                pagination={true}
                paginationPerPage={16}
                paginationRowsPerPageOptions={[16, 32, 64]}
                highlightOnHover={true}
                onRowClicked={(row) => navigate(`/admin/order/${row.orderId}`)}
                columns={columns}
                
                data={(orders || []).filter((order : any) => { 
                    if(period === undefined) 
                        return order 
                    else if(new Date(order.createdAt).getFullYear() === parseInt(period ?? "")) 
                        return order
                    return []
                })}
            />
        </div>
    );
}