import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Form,
    FormGroup,
    Input,
    Label,
    Row
} from "reactstrap";
import {Formik} from "formik";
import * as Yup from "yup";
import {useMutation} from "react-query";
import LocalStorageService from "../Services/Storage/LocalStorageService";
import http from "../Services/Network/http";
import {useNavigate} from "react-router";
import {Link} from "@mui/material";

export const Login = () => {
    let navigate = useNavigate();
    const loginMutation = useMutation(loginCredential => http.post('/Authorization/Authorize', loginCredential), {
        onSuccess: (data) => {
            if(data.firstLogin) {
                navigate("/auth/verify/" + data.firstLoginToken);
            } else {
                LocalStorageService.setToken({access_token: data.token, access_role: data.role});
                if(data.role === "Supplier") {
                    navigate("/orders");
                }
                else {
                    navigate("/Products");
                }
            }
        },
        onError: (error) => {
            console.log(error.response.data);
        },
    });
    
    return (
        <Container>
            <Row>
            <Col md={3}><></></Col>
            <Col md={6}>
                <Card>
                    <CardHeader>
                        Bruker pålogging
                    </CardHeader>
                    <CardBody>
                    
                        <Formik
                            initialValues={{
                                username: "",
                                password: ""
                            }}
                            validationSchema={Yup.object({
                                username: Yup.string()
                                    .required(),
                                password: Yup.string()
                                    .required(),
                            })}
                            onSubmit={(values, {setSubmitting}) => {
                                setSubmitting(false);
                                loginMutation.mutate({
                                    username: values.username,
                                    password: values.password
                                });
                            }}>
                            {
                                ({
                                     values,
                                     errors,
                                     touched,
                                     handleChange,
                                     handleBlur,
                                     isSubmitting,
                                     isValid,
                                     handleSubmit
                                 }) => (
                                    <Form onSubmit={handleSubmit} noValidate>
                                        <FormGroup>
                                            <Label for="username">
                                                Ansatt.Nr
                                            </Label>
                                            <Input
                                                autoComplete="username"
                                                id="username"
                                                name="username"
                                                placeholder="Ansatt.Nr"
                                                invalid={touched.username && !!errors.username}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.username}
                                            />
                                        </FormGroup>
                                        {' '}
                                        <FormGroup>
                                            <Label for="password">
                                                Passord
                                            </Label>
                                            <Input
                                                autoComplete="current-password"
                                                id="password"
                                                name="password"
                                                placeholder="Passord"
                                                type="password"
                                                invalid={touched.password && !!errors.password}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.password}
                                            />
                                        </FormGroup>
                                        <Link style={{float: "right", cursor: 'pointer'}} onClick={() => navigate("/auth/forgot")}>Glemt passord?</Link>
                                        <Button type="submit" disabled={isSubmitting || !isValid}>
                                            {isSubmitting ? 'Laster...' : 'Logg inn'}
                                        </Button>
                                    </Form>
                                )}
                        </Formik>
                    </CardBody>
                </Card>
            </Col>
            <Col md={3}><></></Col>
            </Row>
        </Container>
    );
}