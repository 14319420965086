import {useQuery} from "react-query";
import http from "../../Services/Network/http";
import DataTable from 'react-data-table-component';
import {Link} from "@mui/material";
import {useNavigate} from "react-router";


export const Credit = () => {
    var navigate = useNavigate();
    const { data: credits } = useQuery(
        "fetchUserCredit",
        () => http.get(`/Account/GetCredits`));

    const columns = [
        {
            name: 'Poeng gitt',
            selector: row => row.credit,
        },
        {
            name: 'Poeng brukt',
            selector: row => <Link style={{cursor: 'pointer'}} onClick={() => navigate(`/account/orders/${row.periodYear}`)}>{row.usedCredit}</Link>,
        },
        {
            name: 'Poeng rest',
            selector: row => row.credit-row.usedCredit,
        },
        {
            name: 'Periode',
            selector: row => row.periodYear,
        }
    ];


    return (
        <div>
            <h2>Konto &gt; Mine poeng</h2>
            <DataTable
                columns={columns}
                data={(credits || [])}
            />
            
        
        </div>
    );
}