import {useNavigate} from "react-router";
import {useMutation, useQuery, useQueryClient} from "react-query";
import http from "../../../Services/Network/http";
import {BsFillTrashFill} from "react-icons/bs";
import {Button} from "reactstrap";
import DataTable from "react-data-table-component";


export const AdminPageList = () => {
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const deleteMutation = useMutation(id => http.remove(`/Page/${id}`), {
        onSuccess: () => {
            queryClient.invalidateQueries(["fetchPages"]);
        },
        onError: (error : any) => {
            console.log(error.response.data);
        },
    });

    const { data: pages } = useQuery(
        "fetchPages",
        () => http.get(`/Page`));

    const columns = [
        {
            name: 'Tittel',
            selector: (row : any) => row.title,
            grow: 3
        },
        {
            name: 'Vis i meny',
            selector: (row : any) => row.showInMenu ? 'Ja' : 'Nei',
            grow: 3
        },
        {
            name: 'Aktiv',
            selector: (row : any) => row.active ? 'Ja' : 'Nei',
            grow: 3
        },
        {

            cell: (row : any) => (
                <>
                    <BsFillTrashFill cursor="Pointer" onClick={() => window.confirm("Er du sikker på at du vil slette siden?") ? deleteMutation.mutate(row.pageId) : ""} />
                </>),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        }
    ];


    return (
        <div>
            <h2 style={{float:'left'}}>Admin &gt; Menysider</h2>
            <Button color="primary"  style={{float:'right'}} size="sm" onClick={() => navigate("/admin/page")}>Legg til ny side</Button>
            <DataTable
                highlightOnHover={true}
                onRowClicked={(row) => navigate(`/admin/page/${row.pageId}`)}
                columns={columns}
                data={(pages || [])}
            />
        </div>
    );
}