import {useRoutes} from "react-router";
import {AppRoutesRouter} from "./app-routes";
import { MainLayout } from "./Layouts/MainLayout";
import { AuthLayout } from "./Layouts/AuthLayout";


export const MainRouter = () => {
    return useRoutes([
        { path: "/auth/*", element: <AuthLayout /> },
        {
            path: '/*',
            element: <MainLayout />,
        },
    ])
}
