import * as Yup from "yup";
import {Button, Col, Form, FormGroup, Input, Label, Row} from "reactstrap";
import {Formik} from "formik";
import {useMutation, useQuery} from "react-query";
import http from "../../../Services/Network/http";

import {useParams} from "react-router-dom";
import {useNavigate} from "react-router";
import {Html4Entities as htmlEntities} from "html-entities/lib/html4-entities";
import ReactQuill, {Quill} from "react-quill";
import 'react-quill/dist/quill.snow.css';

const Block = Quill.import('blots/block');
Block.tagName = 'DIV';
Quill.register(Block, true);

interface page {
    pageId: number
    title: string
    content: string
    showInMenu: boolean
    active: boolean
    order: number
}

export const AdminPage = () => {
    const { id } = useParams();

    const navigate = useNavigate();

    const pageMutation = useMutation((pageForSaving : page) => pageForSaving.pageId === null ? http.post('/page', pageForSaving) : http.put('/page', pageForSaving), {
        onSuccess: (data) => {
            navigate("/admin/pages");
        },
        onError: (error : any, variables, context) => {
            console.log(error.response.data);
        },
    });

    const { data: page } = useQuery(
        ["fetchPage", id],
        () => http.get(`/page/${id}`),
        {enabled: id !== undefined})

    return (
        <div>
            <Formik
                enableReinitialize={true}
                initialValues={{
                    pageId: page?.pageId ?? null,
                    title: page?.title ?? "",
                    content: page?.content ?? "",
                    showInMenu: page?.showInMenu ?? true,
                    active: page?.active ?? true,
                    order: page?.order ?? 0
                }}
                validationSchema={Yup.object({
                    title: Yup.string().required(),
                    content: Yup.string().required(),
                    order: Yup.number().required()
                })}
                onSubmit={(values, {setSubmitting}) => {
                    setSubmitting(false);
                    pageMutation.mutate({
                        pageId: values.pageId,
                        title: values.title,
                        content: values.content,
                        showInMenu: values.showInMenu,
                        active: values.active,
                        order: values.order
                    });
                     
                }}>
                {
                    ({
                         values,
                         errors,
                         touched,
                         handleChange,
                         handleBlur,
                         isSubmitting,
                         isValid,
                         handleSubmit,
                         setFieldValue
                     }) => (
                        <Form onSubmit={handleSubmit} noValidate>
                            <Row>
                                <Col md={12}>
                                    <FormGroup>
                                        <Label for="title">
                                            Tittel
                                        </Label>
                                        <Input
                                            id="title"
                                            name="title"
                                            placeholder="Tittel"
                                            invalid={touched.title && !!errors.title}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.title}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={12}>
                                    <FormGroup>
                                        <Label for="content">Innhold</Label>
                                        <ReactQuill
                                            theme="snow"
                                            id="content"
                                            value={htmlEntities.decode(values.content)}
                                            onChange={(e) => setFieldValue('content', htmlEntities.encode(e))}
                                            modules={{
                                                clipboard: {
                                                    matchVisual: true
                                                }
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={4}>
                                    <FormGroup>
                                        <Label for="order">
                                            Rekkefølge
                                        </Label>
                                        <Input
                                            id="order"
                                            name="order"
                                            placeholder="Rekkefølge"
                                            invalid={touched.order && !!errors.order}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.order}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={4}>
                                    <FormGroup>
                                        <Label for="showInMenu">
                                            Vis i meny
                                        </Label>
                                        <Input
                                            type="select"
                                            name="showInMenu"
                                            id="showInMenu"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.showInMenu}>
                                            <option value="1">Ja</option>
                                            <option value="0">Nei</option>
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col md={4}>
                                    <FormGroup>
                                        <Label for="active">
                                            Aktiv
                                        </Label>
                                        <Input
                                            type="select"
                                            name="active"
                                            id="active"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.active}>
                                            <option value="1">Ja</option>
                                            <option value="0">Nei</option>
                                        </Input>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Button type="submit" disabled={isSubmitting || !isValid}>
                                {isSubmitting ? 'Laster...' : 'Lagre'}
                            </Button>
                        </Form>
                    )}
            </Formik>
        </div>
    )
}