import {useParams} from "react-router-dom";
import {useNavigate} from "react-router";
import {useMutation, useQuery} from "react-query";
import http from "../../../Services/Network/http";
import {Formik} from "formik";
import * as Yup from "yup";
import {Button, Col, Form, FormGroup, Input, Label, Row} from "reactstrap";
import moment from "moment";

interface userCreditForSaving {
    userId: number
    credit: number
    periodYear: number
}

export const AdminUserAddUpdateCredit = () => {
    const {userId, periodYear} = useParams();

    const navigate = useNavigate();

    const creditMutation = useMutation((userCreditForSaving: userCreditForSaving) => periodYear !== undefined ? http.put('/User/UpdateCreditForUser', userCreditForSaving) : http.post('/User/AddCreditToUser', userCreditForSaving), {
        onSuccess: (data) => {
            navigate(`/admin/user/credit/${userId}`);
        },
        onError: (error: any, variables, context) => {
        },
    });

    const { data: userCredit } = useQuery(
        ["fetchUserCredit", periodYear],
        () => http.get(`/User/GetCreditForUserId/${userId}/${periodYear}`),
        {enabled: periodYear !== undefined})
    
    const periodYears = periodYear !== undefined && ![moment().year(), moment().year() + 1].includes(parseInt(periodYear),0) ? [parseInt(periodYear), moment().year(), moment().year() + 1] : [moment().year(), moment().year() + 1];

    
    return (
        <div>
            <Formik
                enableReinitialize={true}
                initialValues={{
                    credit: userCredit?.credit ?? 100,
                    periodYear: userCredit?.periodYear ?? new Date().getFullYear()
                }}
                validationSchema={Yup.object({
                    credit: Yup.number().required().min(1),
                    periodYear: Yup.number().required().min(periodYears[0]).max(periodYears[periodYears.length-1])
                })}
                onSubmit={(values, {setSubmitting}) => {
                    setSubmitting(false);
                    if(userId !== undefined) {
                        creditMutation.mutate({
                            userId: parseInt(userId),
                            credit: values.credit,
                            periodYear: values.periodYear
                        });
                    }
                }}>
                {
                    ({
                         values,
                         errors,
                         touched,
                         handleChange,
                         handleBlur,
                         isSubmitting,
                         isValid,
                         handleSubmit
                     }) => (
                        <Form onSubmit={handleSubmit} noValidate>
                            <Row>
                                <Col md={12}>
                                    <FormGroup>
                                        <Label for="credit">
                                            Poeng
                                        </Label>
                                        <Input
                                            id="credit"
                                            name="credit"
                                            placeholder="Skriv inn poeng som skal legges til"
                                            invalid={touched.credit && !!errors.credit}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.credit}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            {periodYear === undefined &&
                                <Row>
                                    <Col md={12}>
                                        <FormGroup>
                                            <Label for="periodYear">Periode</Label>
                                            <Input
                                                type="select"
                                                name="periodYear"
                                                id="periodYear"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.periodYear}>
                                                {periodYears.map((periodYear) => <option
                                                    value={periodYear}>{periodYear}</option>)}
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            }
                            <Button type="submit" disabled={isSubmitting || !isValid}>
                                {isSubmitting ? 'Laster...' : 'Legg til'}
                            </Button>
                        </Form>
                    )}
            </Formik>
        </div>
    )
}