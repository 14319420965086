import {useMutation, useQuery, useQueryClient} from "react-query";
import http from "../../../Services/Network/http";
import DataTable from "react-data-table-component";
import {BsFillTrashFill} from "react-icons/bs";
import {Button} from "reactstrap";
import {useNavigate} from "react-router";

export const AdminSupplierList = () => {
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const deleteSupplierMutation = useMutation(supplierId => http.remove(`/supplier/${supplierId}`), {
        onSuccess: () => {
            queryClient.invalidateQueries(["fetchSuppliers"]);
        },
        onError: (error) => {
            console.log(error.response.data);
        },
    });

    const { data: suppliers } = useQuery(
        "fetchSuppliers",
        () => http.get(`/Supplier`));

    const columns = [
        {
            name: 'Leverandør',
            selector: row => row.name,
            grow: 3
        },
        {
            name: 'Epost',
            selector: row => row.email,
            grow: 3
        },
        {

            cell: (row) => (
                <>
                    <BsFillTrashFill cursor="Pointer" onClick={() => window.confirm("Er du sikker på at du vil slette leverandøren?") ? deleteSupplierMutation.mutate(row.supplierId) : ""} />
                </>),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        }
    ];


    return (
        <div>
            <h2 style={{float:'left'}}>Admin &gt; Leverandører</h2>
            <Button color="primary"  style={{float:'right'}} size="sm" onClick={() => navigate("/admin/supplier")}>Legg til ny leverandør</Button>
            <DataTable
                highlightOnHover={true}
                onRowClicked={(row) => navigate(`/admin/supplier/${row.supplierId}`)}
                columns={columns}
                data={(suppliers || [])}
            />
        </div>
    );
}