import {Container} from "reactstrap";
import React from "react";
import {Route, Routes} from "react-router-dom";
import {Login} from "../Pages/Login";
import {NavMenu} from "../components/NavMenu";
import {Forgot} from "../Pages/Forgot";
import {Verify} from "../Pages/Verify";

export const AuthLayout = () => {
    return (
        <div>
            <NavMenu hideMenuItems={true} openShoppingCart={() => {}} />
            <Container style={{marginTop: '20vh'}}>
                <Routes>
                    <Route path='/login' element={<Login />} />
                    <Route path='/forgot' element={<Forgot />} />
                    <Route path='/verify/:verificationToken' element={<Verify />} />
                </Routes>
            </Container>
        </div>
    );
}