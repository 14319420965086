import {useNavigate} from "react-router";
import {useMutation, useQuery, useQueryClient} from "react-query";
import http from "../../../Services/Network/http";
import {BsFillTrashFill} from "react-icons/bs";
import {Button} from "reactstrap";
import DataTable from "react-data-table-component";
import {Card} from "@mui/material";



const ExpandedComponent = ({ data, columns } : any) => {
    const navigate = useNavigate();
    return <Card variant="outlined" style={{width:  'calc(100% - 50px)', marginLeft: '50px'}}>
        <DataTable
            noTableHead={true}
            highlightOnHover={true}
            onRowClicked={(row) => navigate(`/admin/costAccount/${row.costAccountId}`)}
            columns={columns}
            expandableRows
            expandableRowDisabled={(row) => (row.costAccounts || []).length === 0}
            expandableRowExpanded ={(row: any) => (row.costAccounts || []).length !== 0}
            expandableRowsComponent={({data}) => ExpandedComponent({data, columns})}
            data={(data.costAccounts || []).sort((a : any, b : any) => a.costAccountNumber > b.costAccountNumber ? 1 : -1)}
        />
    </Card>;
}
    
export const AdminCostAccountList = () => {
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const deleteCostAccountMutation = useMutation(costAccountId => http.remove(`/CostAccount/${costAccountId}`), {
        onSuccess: () => {
            queryClient.invalidateQueries(["fetchCostAccounts"]);
        },
        onError: (error : any) => {
            console.log(error.response.data);
        },
    });

    const { data: costAccounts } = useQuery(
        "fetchCostAccounts",
        () => http.get(`/CostAccount`));


    const columns = [
        {
            name: 'Kost.Nr',
            selector: (row : any) => row.costAccountNumber,
            grow: 3
        },
        {
            name: 'Kost.Navn',
            selector: (row : any) => row.costAccountName,
            grow: 3
        },
        {
            name: 'Kost.Sted',
            selector: (row : any) => row.costAccountLocation,
            grow: 3
        },
        {
            name: 'Ansvarlig',
            selector: (row : any) => row.responsibleUser?.fullName,
            grow: 3
        },
        {

            cell: (row : any) => (
                <>
                    {(row.costAccounts || []).length === 0 && <BsFillTrashFill cursor="Pointer" onClick={() => window.confirm("Er du sikker på at du vil slette kostnadskontoen?") ? deleteCostAccountMutation.mutate(row.costAccountId) : ""} />}
                </>),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        }
    ];

    return (
        <div>
            <h2 style={{float:'left'}}>Admin &gt; Kostnadskontoer</h2>
            <Button color="primary"  style={{float:'right'}} size="sm" onClick={() => navigate("/admin/costAccount")}>Legg til ny kostnadskonto</Button>
            <DataTable
                highlightOnHover={true}
                onRowClicked={(row) => navigate(`/admin/costAccount/${row.costAccountId}`)}
                columns={columns}
                expandableRows
                expandableRowDisabled={(row) => (row.costAccounts || []).length === 0}
                expandableRowExpanded ={(row: any) => (row.costAccounts || []).length !== 0}
                expandableRowsComponent={({data}) => ExpandedComponent({ data, columns, level: 1 })}
                data={(costAccounts || []).sort((a : any, b : any) => a.costAccountNumber > b.costAccountNumber ? 1 : -1).filter(function (item : any) {
                    return item.parentCostAccountId === null;
                })}
            />
        </div>
    );
}