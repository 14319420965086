import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Form,
    FormGroup,
    Input,
    Label,
    Row
} from "reactstrap";
import {Formik} from "formik";
import * as Yup from "yup";
import {useMutation, useQuery} from "react-query";
import LocalStorageService from "../Services/Storage/LocalStorageService";
import http from "../Services/Network/http";
import {useNavigate} from "react-router";
import {Link} from "@mui/material";
import {toast} from "react-toastify";
import {useParams} from "react-router-dom";

export const Verify = () => {
    const {verificationToken} = useParams();
    let navigate = useNavigate();
    const verifyMutation = useMutation(requestObjects => http.post(`/Authorization/CompleteForgotPassword?token=${encodeURIComponent(requestObjects.verificationToken)}&newPassword=${encodeURIComponent(requestObjects.password)}`), {
        onSuccess: (data) => {
            LocalStorageService.setToken({access_token: data.token, access_role: data.role});
            if(data.role === "Supplier") {
                navigate("/orders");
            }
            else {
                navigate("/Products");
            }
        },
        onError: (error) => {
            console.log(error.response.data);
        },
    });
    
    useQuery(["checkVerificationToken", verificationToken],
        () => http.post(`/Authorization/CheckForgotPasswordToken?token=${encodeURIComponent(verificationToken)}`), 
        { retry: false, onError: (error) => {
            navigate("/auth/login");
        }});
    
    return (
        <Container>
            <Row>
            <Col md={3}><></></Col>
            <Col md={6}>
                <Card>
                    <CardHeader>
                        Endre passord
                    </CardHeader>
                    <CardBody>
                    
                        <Formik
                            initialValues={{
                                password1: "",
                                password2: "",
                                verificationToken
                            }}
                            validationSchema={Yup.object({
                                password1: Yup.string().min('6').required(),
                                password2: Yup.string().min('6').required().oneOf([Yup.ref('password1'), null], 'Passwords must match')
                            })}
                            onSubmit={(values, {setSubmitting}) => {
                                setSubmitting(false);
                                verifyMutation.mutate({
                                    verificationToken: values.verificationToken,
                                    password: values.password1
                                });
                            }}>
                            {
                                ({
                                     values,
                                     errors,
                                     touched,
                                     handleChange,
                                     handleBlur,
                                     isSubmitting,
                                     isValid,
                                     handleSubmit
                                 }) => (
                                    <Form onSubmit={handleSubmit} noValidate>
                                        <FormGroup>
                                            <Label for="password">
                                                Passord <span style={{color: 'gray', fontSize: 12}}>(requires 6 characters+)</span>
                                            </Label>
                                            <Input
                                                autoComplete="password"
                                                id="password1"
                                                name="password1"
                                                placeholder="Passord"
                                                invalid={touched.password1 && !!errors.password1}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.password1}
                                                type={"password"}
                                            />
                                            
                                        </FormGroup>

                                        <FormGroup>
                                            <Label for="password2">
                                                Passord bekreftelse
                                            </Label>
                                            <Input
                                                autoComplete="password"
                                                id="password2"
                                                name="password2"
                                                placeholder="Passord bekreftelse"
                                                invalid={touched.password2 && !!errors.password2}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.password2}
                                                type={"password"}
                                            />
                                        </FormGroup>
                                        <Link style={{float: "right", cursor: 'pointer'}} onClick={() => navigate("/auth/login")}>Gå tilbake</Link>
                                        <Button type="submit" disabled={isSubmitting || !isValid}>
                                            {isSubmitting ? 'Laster...' : 'Endre passord'}
                                        </Button>
                                    </Form>
                                )}
                        </Formik>
                    </CardBody>
                </Card>
            </Col>
            <Col md={3}><></></Col>
            </Row>
        </Container>
    );
}