import {useNavigate} from "react-router";
import {useMutation, useQuery, useQueryClient} from "react-query";
import http from "../../../Services/Network/http";
import {BsFillTrashFill} from "react-icons/bs";
import {Button} from "reactstrap";
import DataTable from "react-data-table-component";
import {useParams} from "react-router-dom";


export const AdminProductVariantList = () => {
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const deleteProductVariantMutation = useMutation(productVariantId => http.remove(`/product/DeleteProductVariant/${productVariantId}`), {
        onSuccess: () => {
            queryClient.invalidateQueries(["fetchProduct"]);
        },
        onError: (error : any) => {
            console.log(error.response.data);
        },
    });


    const { id } = useParams();

    const { data: product } = useQuery(
        "fetchProduct",
        () => http.get(`/product/GetProduct/${id}`));

    const columns = [
        {
            name: 'Varenummer',
            selector: (row : any) => row.productNr,
            grow: 3
        },
        {
            name: 'Variant',
            selector: (row : any) => row.variant,
            grow: 3
        },
        {

            cell: (row : any) => (
                <>
                    <BsFillTrashFill cursor="Pointer" onClick={() => window.confirm("Er du sikker på at du vil slette produkt varianten?") ? deleteProductVariantMutation.mutate(row.productVariantId) : ""} />
                </>),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        }
    ];


    return (
        <div>
            <h2 style={{float:'left'}}>Admin &gt; Produkt &gt; {product?.title}</h2>
            <Button color="primary"  style={{float:'right'}} size="sm" onClick={() => navigate(`/admin/product/${id}/productVariant`)}>Legg til ny variant</Button>
            <DataTable
                highlightOnHover={true}
                onRowClicked={(row) => navigate(`/admin/product/${id}/productVariant/${row.productVariantId}`)}
                columns={columns}
                data={(product?.productVariants || [])}
            />
        </div>
    );
}