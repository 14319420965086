import * as Yup from "yup";
import {Button, Col, Form, FormGroup, Input, Label, Row} from "reactstrap";
import {Formik} from "formik";
import {useMutation, useQuery} from "react-query";
import http from "../../../Services/Network/http";
import {useParams} from "react-router-dom";
import {useNavigate} from "react-router";


export const AdminProductCategory = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const productCategoryMutation = useMutation(productCategoryForSaving => productCategoryForSaving.productCategoryId === null ? http.post('/product/AddProductCategory', productCategoryForSaving) : http.put('/product/UpdateProductCategory', productCategoryForSaving), {
        onSuccess: (data) => {
            navigate("/Admin/ProductCategoriesList");
        },
        onError: (error, variables, context) => {
            console.log(error.response.data);
        },
    });

    const { data: productCategory } = useQuery(
        ["fetchProductCategory", id],
        () => http.get(`/Product/GetProductCategory/${id}`),
        {enabled: id !== undefined})


    return (
        <div>
            <Formik
                enableReinitialize={true}
                initialValues={{
                    productCategoryId: productCategory?.productCategoryId ?? null,
                    title: productCategory?.title ?? "",
                }}
                validationSchema={Yup.object({
                    title: Yup.string().required(),
                })}
                onSubmit={(values, {setSubmitting}) => {
                    setSubmitting(false);

                    productCategoryMutation.mutate({
                        productCategoryId: values.productCategoryId,
                        title: values.title
                    });
                }}>
                {
                    ({
                         values,
                         errors,
                         touched,
                         handleChange,
                         handleBlur,
                         isSubmitting,
                         isValid,
                         handleSubmit
                     }) => (
                        <Form onSubmit={handleSubmit} noValidate>
                            <Row>
                                <Col md={12}>
                                    <FormGroup>
                                        <Label for="title">
                                            Kategori navn
                                        </Label>
                                        <Input
                                            id="title"
                                            name="title"
                                            placeholder="Kategori navn"
                                            invalid={touched.title && !!errors.title}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.title}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Button type="submit" disabled={isSubmitting || !isValid}>
                                {isSubmitting ? 'Laster...' : 'Lagre'}
                            </Button>
                        </Form>
                    )}
            </Formik>
        </div>
    )
}