import { Navigate } from 'react-router-dom'
import React from "react";
import LocalStorageService from "../Services/Storage/LocalStorageService";

export enum Role {
    Normal = 'Normal' as any,
    Supplier = 'Supplier' as any,
    Admin = 'Admin' as any
}

interface Props {
    component: React.ComponentType
    path?: string
    roles?: Array<any>
}

export const PrivateRoute: React.FC<Props> = ({ component: RouteComponent, roles }) => {
    
    const isAuthenticated = LocalStorageService.getAccessToken() !== null;
    const userRole = LocalStorageService.getAccessRole();
    
    let userHasRequiredRole = userRole && roles && Object.keys(Role)[Object.values(roles).indexOf(userRole)];
    console.log(userHasRequiredRole, userRole, Role, roles)
    if (isAuthenticated && (roles === undefined || userHasRequiredRole)) {
        return <RouteComponent />
    }

    if (isAuthenticated && (roles !== undefined && !userHasRequiredRole)) {
        return <p>Access denied.</p>
    }

    return <Navigate to="/auth/login" />
}