import {Button, Col, Row} from "reactstrap";
import config from "../config";
import Select from "react-select";
import React, {useContext, useEffect, useState} from "react";
import {ShoppingCartContext} from "../Context/ShoppingCartContext";
import {useNavigate} from "react-router";
import {useTranslation} from "react-i18next";


interface productVariant {
    productVariantId: number
    productNr: string
    providerNr: string
    productId: number
    variant: string
}

interface productItem {
    productId: number
    title: string
    shortDescription: string
    supplier: any
    price: number
    productImages: any
    productVariants: productVariant[]
}
interface latestVariantSize {
    productId: number
    productVariantId: number
}

interface productItemProps {
    product: productItem,
    latestVariantSize: latestVariantSize,
    disabled: boolean
}

export const ProductItem = ({product, latestVariantSize, disabled}:productItemProps) => {

    const {t} = useTranslation('base');
    
    const navigate = useNavigate();
    const [selectedVariant, setSelectedVariant] = useState<null | productVariant>(null);
    const shoppingCartContext = useContext(ShoppingCartContext);
    
    useEffect(() => {
        if(latestVariantSize !== undefined) {
            let productVariant = product.productVariants.find((variant: productVariant) => variant.productVariantId === latestVariantSize.productVariantId);
            if(productVariant !== undefined) {
                setSelectedVariant(productVariant);
            }
        } else if (product.productVariants.length === 1) {
            setSelectedVariant(product.productVariants[0]);
        }
    } , [product, latestVariantSize]);
    
    return (
        <Row>
            <Col md={3}>
                <img onClick={() => navigate(`/product/${product.productId}`)} alt={"product"} src={`${config.api.url}file/${product.productImages[0]?.file?.viewToken}`} width={"100%"} style={{border: "1px solid lightgray", borderRadius: "0.25rem", cursor: 'pointer'}} />
            </Col>
            <Col md={9}>
                <h4 onClick={() => navigate(`/product/${product.productId}`)} style={{cursor: 'pointer', marginBottom: 0}}>{product.title}</h4>
                <p style={{color: 'gray'}}>{product.supplier.name}</p>
                <p>{product.shortDescription}</p>
                <p>{t('product.points')}: {product.price}</p>
                <Row>
                    <Col md={4}>
                        <Select 
                            options={(product.productVariants || []).map(v => { return { value: v, label: v.variant } })}
                            defaultValue={selectedVariant !== null ? {value: selectedVariant, label: selectedVariant.variant } : undefined}
                            onChange={(newValue) => {
                                newValue !== null ? setSelectedVariant(newValue.value) : setSelectedVariant(null);
                            }}/><br/>
                    </Col>
                    <Col md={8}>
                        <Button size={"md"} style={disabled ? {display:"none"} : {}} disabled={selectedVariant === null || disabled} onClick={() => shoppingCartContext.dispatch({type: "add", item: {
                                productId: product.productId,
                                productVariantId: selectedVariant?.productVariantId,
                                variant: selectedVariant?.variant,
                                title: product.title,
                                shortDescription: product.shortDescription,
                                imageUrl: `${config.api.url}file/${product.productImages[0]?.file?.viewToken}`,
                                quantity: 1,
                                price: product.price
                            }})} >{t('product.addToCart')}</Button>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}