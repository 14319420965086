// LocalStorageService.js
const LocalStorageService = (function(){
    let _service;
    function _getService() {
        if(!_service) {
            _service = this;
            return _service
        }
        return _service
    }
    function _setToken(tokenObj) {
        localStorage.setItem(`access_token`, tokenObj.access_token);
        localStorage.setItem(`access_role`, tokenObj.access_role);
    }
    function _getAccessToken() {
        return localStorage.getItem(`access_token`);
    }
    function _getAccessRole() {
        return localStorage.getItem(`access_role`);
    }
    function _getRefreshToken() {
        return localStorage.getItem(`refresh_token`);
    }
    function _clearToken() {
        localStorage.removeItem(`access_token`);
        localStorage.removeItem(`access_role`);
    }
    return {
        getService : _getService,
        setToken : _setToken,
        getAccessToken : _getAccessToken,
        getAccessRole : _getAccessRole,
        getRefreshToken : _getRefreshToken,
        clearToken : _clearToken
    }
})();
export default LocalStorageService;