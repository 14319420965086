import {useNavigate} from "react-router";
import {useQuery} from "react-query";
import http from "../../Services/Network/http";
import DataTable from "react-data-table-component";
import moment from "moment";
import {useParams} from "react-router-dom";

export const Orders = () => {
    const {period} = useParams();
    const navigate = useNavigate();

    const { data: orders } = useQuery(
        "fetchOrders",
        () => http.get(`/Account/Orders`));

    const columns = [
        {
            name: 'Ordre.Nr',
            selector: (row : any) => row.orderId,
            grow: 3
        },
        {
            name: 'Status',
            selector: (row : any) => row.orderStatus?.title,
            grow: 3
        },
        {
            name: 'Leverandør',
            selector: (row : any) => row.supplier?.name,
            grow: 3
        },
        {
            name: 'Poeng totalt',
            selector: (row : any) => row.totalSum,
            grow: 3
        },
        {
            name: 'Opprettet',
            selector: (row : any) => moment(row.createdAt).format('DD.MM.yyyy HH:mm'),
            grow: 3
        }
    ];


    return (
        <div>
            <h2 style={{float:'left'}}>Admin &gt; Ordrehistorikk</h2>
            <DataTable
                highlightOnHover={true}
                onRowClicked={(row) => navigate(`/account/order/${row.orderId}`)}
                columns={columns}
                data={(orders || []).filter((order : any) => { 
                    if(period === undefined) 
                        return order 
                    else if(new Date(order.createdAt).getFullYear() === parseInt(period ?? "")) 
                        return order
                    return []
                })}
            />
        </div>
    );
}