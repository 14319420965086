export const AppRoutesRouter = {
    Home: '/',
    Login: '/Login/*',
    Forgot: '/Login/*',
    Products: '/Products',
    ProductsByCategoryId: '/Products/:id',
    Checkout: '/checkout'
}

export const AppRoutesNavigation = {
    Home: '/',
    Login: '/login',
    Products: '/Products',
    
    
}