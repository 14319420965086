import {Button, Col, Row} from "reactstrap";
import config from "../config";
import Select from "react-select";
import React, {useContext, useState} from "react";
import {ShoppingCartContext} from "../Context/ShoppingCartContext";
import {useQuery} from "react-query";
import http from "../Services/Network/http";
import {useParams} from "react-router-dom";
import {Html4Entities as htmlEntities} from "html-entities/lib/html4-entities";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import {useTranslation} from "react-i18next";

interface productVariant {
    productVariantId: number
    productNr: string
    providerNr: string
    productId: number
    variant: string
}

const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 1
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 1
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 1
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};

export const Product = () => {
    const { id } = useParams();
    
    const [selectedVariant, setSelectedVariant] = useState<null | productVariant>(null);
    const shoppingCartContext = useContext(ShoppingCartContext);
    const [t] = useTranslation('base');
    const { data: product } = useQuery(
        ["fetchProduct", id],
        () => http.get(`/Product/GetProduct/${id}`))
    console.log((product?.productImages?.map((image: any, index: number) => [{original: `${config.api.url}file/${image?.file?.viewToken}`, thumbnail:`${config.api.url}file/${image?.file?.viewToken}` }]) || []))
    return (
        <Row>
            <Col md={3}>
                <Carousel showDots={true} infinite={true} responsive={responsive}>
                    {(product?.productImages || []).map((productImage : any, index : number) => (
                        <img key={index} alt={"product"} src={`${config.api.url}file/${productImage.file?.viewToken}`} width={"100%"} style={{border: "1px solid lightgray", borderRadius: "0.25rem"}} />
                    ))}
                </Carousel>
            </Col>
            <Col md={9}>
                <h4>{product?.title}</h4>
                <p>{t('product.points')}: {product?.price}</p>
                <Row>
                    <Col md={4}>
                        <Select 
                            options={(product?.productVariants || []).map((v : any) => { return { value: v, label: v.variant } })}
                            onChange={(newValue : any) => {
                                newValue !== null ? setSelectedVariant(newValue.value) : setSelectedVariant(null);
                            }}/><br/>
                    </Col>
                    <Col md={8}>
                        <Button size={"md"} disabled={selectedVariant === null} onClick={() => shoppingCartContext.dispatch({type: "add", item: {
                                productId: product.productId,
                                productVariantId: selectedVariant?.productVariantId,
                                variant: selectedVariant?.variant,
                                title: product.title,
                                shortDescription: product.shortDescription,
                                imageUrl: `${config.api.url}file/${product.productImages[0]?.file?.viewToken}`,
                                quantity: 1,
                                price: product.price
                            }})} >{t('product.addToCart')}</Button>
                    </Col>
                </Row>
                <div dangerouslySetInnerHTML={{ __html: htmlEntities.decode(product?.description) }}/>
                
                
            </Col>
        </Row>
    )
}