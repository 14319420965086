import React from 'react';
import {Container} from "reactstrap";
import {useQuery} from "react-query";
import http from "../../Services/Network/http";
import Loader from "react-loader-spinner";
import {ProductItem} from "../../components/ProductItem";
import {useTranslation} from "react-i18next";

export const SupplierProducts = () => {
    const { data: products, isLoading } = useQuery(
        ["fetchProducts"],
        () => http.get(`/Product/GetSupplierProducts`))
    

    const [t] = useTranslation('base');
    return (
        <div>
            <Container>
                <h2>{t('product.products')}</h2>
                {isLoading && (
                    <Loader
                        type="ThreeDots"
                        color="#000"
                        height={30}
                        width={30}
                    />
                )}
                {(products || []).map((product, index) => (
                    <div key={product.productId}>
                        <ProductItem product={product} latestVariantSize={undefined} disabled />
                        <hr/>
                    </div>
                ))}
            </Container>
        </div>
    );
}
