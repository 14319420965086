import * as Yup from "yup";
import {Button, Col, Form, FormGroup, Input, Label, Row} from "reactstrap";
import {Formik} from "formik";
import {useMutation, useQuery} from "react-query";
import http from "../../Services/Network/http";
import {useNavigate} from "react-router";

export const Edit = () => {

    const navigate = useNavigate();
    
    const { data: account } = useQuery(
        ["fetchAccountInfo"],
        () => http.get(`/account`))

    const accountMutation = useMutation(accountForSaving => http.put('/Account', accountForSaving), {
        onSuccess: () => {
            navigate("/products");
        },
        onError: (error) => {
            console.log(error.response.data);
        },
    });
    
    return (<div>
        <h2>Konto &gt; Endre informasjon</h2>
        <Formik
            enableReinitialize={true}
            initialValues={{
                firstName: account?.firstName ?? "",
                lastName: account?.lastName ?? "",
                email: account?.email ?? "",
                cellPhone: account?.cellPhone ?? "",
            }}
            validationSchema={Yup.object({
                firstName: Yup.string().min(2).required(),
                lastName: Yup.string().min(2).required(),
                email: Yup.string().email("Must be a valid email").required(),
                cellPhone: Yup.string().min(8).max(8).required(),
            })}
            onSubmit={(values, {setSubmitting}) => {
                setSubmitting(false);
                console.log(values);
                accountMutation.mutate({
                    firstName: values.firstName,
                    lastName: values.lastName,
                    email: values.email,
                    cellPhone: values.cellPhone,
                });
            }}>
            {
                ({
                     values,
                     errors,
                     touched,
                     handleChange,
                     handleBlur,
                     isSubmitting,
                     isValid,
                     handleSubmit
                 }) => (
                    <Form onSubmit={handleSubmit} noValidate>
                        <Row>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="firstName">
                                        Fornavn
                                    </Label>
                                    <Input
                                        id="firstName"
                                        name="firstName"
                                        placeholder="Fornavn"
                                        invalid={touched.firstName && !!errors.firstName}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.firstName}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="lastName">
                                        Etternavn
                                    </Label>
                                    <Input
                                        id="lastName"
                                        name="lastName"
                                        placeholder="Etternavn"
                                        invalid={touched.lastName && !!errors.lastName}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.lastName}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <FormGroup>
                                    <Label for="email">
                                        E-post
                                    </Label>
                                    <Input
                                        id="email"
                                        name="email"
                                        placeholder="E-post"
                                        invalid={touched.email && !!errors.email}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.email}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <FormGroup>
                                    <Label for="cellPhone">
                                        Mobilnummer
                                    </Label>
                                    <Input
                                        id="cellPhone"
                                        name="cellPhone"
                                        placeholder="Mobilnummer"
                                        invalid={touched.cellPhone && !!errors.cellPhone}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.cellPhone}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>

                        <Button type="submit" disabled={isSubmitting || !isValid}>
                            {isSubmitting ? 'Laster...' : 'Lagre'}
                        </Button>
                    </Form>
                )}
        </Formik>
        </div>);
}