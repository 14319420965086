import React from 'react';
import {Container} from "reactstrap";
import {useQuery} from "react-query";
import http from "../Services/Network/http";
import {useParams} from "react-router-dom";
import Loader from "react-loader-spinner";
import {ProductItem} from "../components/ProductItem";
import {useTranslation} from "react-i18next";

export const Products = () => {
    const { id } = useParams();
    const { data: products, isLoading } = useQuery(
        ["fetchProducts", id],
        () => http.get(`/Product/GetProducts/${id ?? ""}`))

    const { data: latestVariantSizes } = useQuery(
        ["fetchLatestVariants"],
        () => http.get(`/Account/GetLatestVariants`))

    const [t] = useTranslation('base');
    return (
        <div>
            <Container>
                <h2>{t('product.products')}</h2>
                {isLoading && (
                    <Loader
                        type="ThreeDots"
                        color="#000"
                        height={30}
                        width={30}
                    />
                )}
                {(products || []).map((product, index) => (
                    <div key={product.productId}>
                        <ProductItem product={product} latestVariantSize={latestVariantSizes?.filter(function(item) {return item.productId === product.productId})[0]}  disabled={false}/>
                        <hr/>
                    </div>
                ))}
            </Container>
        </div>
    );
}
