import {useParams} from "react-router-dom";
import {useQuery} from "react-query";
import http from "../Services/Network/http";
import React from "react";
import {Container} from "reactstrap";
import {Html4Entities as htmlEntities} from "html-entities/lib/html4-entities";

export const Page = () => {
    const { id } = useParams();

    const { data: page } = useQuery(
        ["fetchPage", id],
        () => http.get(`/Page/${id}`))

    return (
        <div>
            <Container>
                <h2>{page?.title}</h2>
                <div dangerouslySetInnerHTML={{ __html: htmlEntities.decode(page?.content) }}/>
            </Container>
        </div>
    );
}