import * as Yup from "yup";
import {Button, Col, Form, FormGroup, Input, Label, Row} from "reactstrap";
import {Formik} from "formik";
import {useMutation, useQuery} from "react-query";
import http from "../../../Services/Network/http";

import {useParams} from "react-router-dom";
import {useNavigate} from "react-router";

interface supplier {
    supplierId: number
    name: string
    email: string
}

export const AdminSupplier = () => {
    const { id } = useParams();

    const navigate = useNavigate();

    const supplierMutation = useMutation((supplierForSaving : supplier) => supplierForSaving.supplierId === null ? http.post('/supplier', supplierForSaving) : http.put('/supplier', supplierForSaving), {
        onSuccess: (data) => {
            navigate("/admin/SupplierList");
        },
        onError: (error : any, variables, context) => {
            console.log(error.response.data);
        },
    });

    const { data: supplier } = useQuery(
        ["fetchSupplier", id],
        () => http.get(`/Supplier/${id}`),
        {enabled: id !== undefined})
    
    return (
        <div>
            <Formik
                enableReinitialize={true}
                initialValues={{
                    supplierId: supplier?.supplierId ?? null,
                    name: supplier?.name ?? "",
                    email: supplier?.email ?? "",
                }}
                validationSchema={Yup.object({
                    name: Yup.string().required(),
                    email: Yup.string().email().required()
                })}
                onSubmit={(values, {setSubmitting}) => {
                    setSubmitting(false);
                    supplierMutation.mutate({
                        supplierId: values.supplierId,
                        name: values.name,
                        email: values.email
                    });
                }}>
                {
                    ({
                         values,
                         errors,
                         touched,
                         handleChange,
                         handleBlur,
                         isSubmitting,
                         isValid,
                         handleSubmit
                     }) => (
                        <Form onSubmit={handleSubmit} noValidate>
                            <Row>
                                <Col md={12}>
                                    <FormGroup>
                                        <Label for="name">
                                            Leverandør
                                        </Label>
                                        <Input
                                            id="name"
                                            name="name"
                                            placeholder="Navn"
                                            invalid={touched.name && !!errors.name}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.name}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={12}>
                                    <FormGroup>
                                        <Label for="email">
                                            Epost
                                        </Label>
                                        <Input
                                            id="email"
                                            name="email"
                                            placeholder="Epost"
                                            invalid={touched.email && !!errors.email}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.email}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Button type="submit" disabled={isSubmitting || !isValid}>
                                {isSubmitting ? 'Laster...' : 'Lagre'}
                            </Button>
                        </Form>
                    )}
            </Formik>
        </div>
    )
}