import {useNavigate} from "react-router";
import {useMutation, useQuery, useQueryClient} from "react-query";
import http from "../../../Services/Network/http";
import {BsFillTrashFill} from "react-icons/bs";
import {Button} from "reactstrap";
import DataTable from "react-data-table-component";
import moment from "moment";


export const AdminSystemMessagesList = () => {
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const deleteMutation = useMutation(id => http.remove(`/System/DeleteMessage/${id}`), {
        onSuccess: () => {
            queryClient.invalidateQueries(["fetchSystemMessages"]);
        },
        onError: (error : any) => {
            console.log(error.response.data);
        },
    });

    const { data: messages } = useQuery(
        "fetchSystemMessages",
        () => http.get(`/System/GetMessages?showAll=true`));

    const columns = [
        {
            name: 'Tittel',
            selector: (row : any) => row.title,
            grow: 3
        },
        {
            name: 'Expires',
            selector: (row : any) => moment(row.expires).format('DD.MM.yyyy HH:mm'),
            grow: 3
        },
        {
            name: 'Aktiv',
            selector: (row : any) => row.active ? 'Ja' : 'Nei',
            grow: 3
        },
        {

            cell: (row : any) => (
                <>
                    <BsFillTrashFill cursor="Pointer" onClick={() => window.confirm("Er du sikker på at du vil slette meldingen?") ? deleteMutation.mutate(row.systemMessageId) : ""} />
                </>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        }
];


    return (
        <div>
            <h2 style={{float:'left'}}>Admin &gt; System meldinger</h2>
    <Button color="primary"  style={{float:'right'}} size="sm" onClick={() => navigate("/admin/system/message")}>Legg til ny melding</Button>
    <DataTable
    highlightOnHover={true}
    onRowClicked={(row) => navigate(`/admin/system/message/${row.systemMessageId}`)}
    columns={columns}
    data={(messages || [])}
    />
    </div>
);
}