import {useParams} from "react-router-dom";
import {useNavigate} from "react-router";
import {useMutation, useQuery} from "react-query";
import http from "../../../Services/Network/http";
import {Formik} from "formik";
import * as Yup from "yup";
import {Button, Col, Form, FormGroup, Input, Label, Row} from "reactstrap";
import CheckboxTree from "react-checkbox-tree";
import React, {useEffect, useState} from "react";

interface user {
    userId: number
    username: string
    email: string
    workTitle: string
    firstName: string
    lastName: string
    cellPhone: string
    userRoleId: number
    supplierId: number | null
    active: boolean
    costAccountId: number | null
}

const getNodeIds = (nodes : any) => {
    let ids : any = [];

    nodes?.forEach(({ value, children } : any) => {
        ids = [...ids, value, ...getNodeIds(children)];
    });

    return ids;
};

export const LeaderUser = () => {
    const { id } = useParams();
    
    const [ expanded, setExpanded ] = useState<string[]>([]);
    const [ checked, setChecked ] = useState<string[]>([]);
    
    const navigate = useNavigate();

    const userMutation = useMutation((userForSaving : user) => userForSaving.userId === null ? http.post('/user', userForSaving) : http.put('/user', userForSaving), {
        onSuccess: (data) => {
            navigate("/account/leader/users");
        },
        onError: (error : any, variables, context) => {
            console.log(error.response.data);
        },
    });

    const { data: user } = useQuery(
        ["fetchUser", id],
        () => http.get(`/User/${id}`),
        {enabled: id !== undefined})

    const { data: costAccounts } = useQuery(
        ["fetchFilteredCostAccounts"],
        () => http.get(`/CostAccount?filtered=true`))
    

    const costAccountData = (costAccounts || []).sort((a : any, b : any) => a.costAccountNumber > b.costAccountNumber ? 1 : -1).filter(function (item : any) {
        return item.parentCostAccountId == null
    }).map((item : any) => {
            return {
                value: item.costAccountId,
                label: `${item.costAccountNumber} - ${item.costAccountName}`,
                children: (item.costAccounts || []).length === 0 ? null : (item.costAccounts || []).sort((a : any, b : any) => a.costAccountNumber > b.costAccountNumber ? 1 : -1).map((item2 : any) => {
                    return {
                        value: item2.costAccountId,
                        label: `${item2.costAccountNumber} - ${item2.costAccountName}`,
                        children: (item2.costAccounts || []).length === 0 ? null : (item2.costAccounts || []).sort((a : any, b : any) => a.costAccountNumber > b.costAccountNumber ? 1 : -1).map((item3 : any) => {
                            return {
                                value: item3.costAccountId,
                                label: `${item3.costAccountNumber} - ${item3.costAccountName}`,
                                children: (item3.costAccounts || []).length === 0 ? null : (item3.costAccounts || []).sort((a : any, b : any) => a.costAccountNumber > b.costAccountNumber ? 1 : -1).map((item4 : any) => {
                                    return {
                                        value: item4.costAccountId,
                                        label: `${item4.costAccountNumber} - ${item4.costAccountName}`,
                                    }
                                })
                            }
                        })
                    }
                })
            }
        }
    );

    

    useEffect(() => {
        if(user?.costAccountId !== null && user?.costAccountId !== undefined) {
            setChecked([user.costAccountId]);
        }
    }, [user])
    
    return (
        <div>
            <Formik
                enableReinitialize={true}
                initialValues={{
                    userId: user?.userId ?? null,
                    username: user?.username ?? "",
                    email: user?.email ?? "",
                    workTitle: user?.workTitle ?? "",
                    firstName: user?.firstName ?? "",
                    lastName: user?.lastName ?? "",
                    cellPhone: user?.cellPhone ?? "",
                    active: user?.active ?? true
                }}
                validationSchema={Yup.object({
                    username: Yup.string().required(),
                    email: Yup.string().email().required(),
                    workTitle: Yup.string().optional(),
                    firstName: Yup.string().required(),
                    lastName: Yup.string().required(),
                    cellPhone: Yup.number().required(),
                    active: Yup.boolean()
                })}
                onSubmit={(values, {setSubmitting}) => {
                    setSubmitting(false);
                    userMutation.mutate({
                        userId: values.userId,
                        username: values.username,
                        email: values.email,
                        workTitle: values.workTitle,
                        supplierId:  null,
                        firstName: values.firstName,
                        lastName: values.lastName,
                        cellPhone: values.cellPhone,
                        userRoleId: 1,
                        active: values.active,
                        costAccountId: checked.length > 0 ? parseInt(checked[0]) : null
                    });
                }}>
                {
                    ({
                         values,
                         errors,
                         touched,
                         handleChange,
                         handleBlur,
                         isSubmitting,
                         isValid,
                         handleSubmit
                     }) => (
                        <Form onSubmit={handleSubmit} noValidate>
                            <Row>
                                <Col md={12}>
                                    <FormGroup>
                                        <Label for="username">
                                            Brukernavn
                                        </Label>
                                        <Input
                                            id="username"
                                            name="username"
                                            placeholder="Brukernavn, ansattnr..."
                                            invalid={touched.username && !!errors.username}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.username}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={12}>
                                    <FormGroup>
                                        <Label for="email">
                                            Epost
                                        </Label>
                                        <Input
                                            id="email"
                                            name="email"
                                            placeholder="Epost"
                                            invalid={touched.email && !!errors.email}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.email}
                                        />
                                    </FormGroup>
                                </Col>
                                
                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="firstName">
                                            Fornavn
                                        </Label>
                                        <Input
                                            id="firstName"
                                            name="firstName"
                                            placeholder="Fornavn"
                                            invalid={touched.firstName && !!errors.firstName}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.firstName}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="lastName">
                                            Etternavn
                                        </Label>
                                        <Input
                                            id="lastName"
                                            name="lastName"
                                            placeholder="Etternavn"
                                            invalid={touched.lastName && !!errors.lastName}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.lastName}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={12}>
                                    <FormGroup>
                                        <Label for="workTitle">
                                            Stillingstittel
                                        </Label>
                                        <Input
                                            id="workTitle"
                                            name="workTitle"
                                            placeholder="Stillingstittel"
                                            invalid={touched.workTitle && !!errors.workTitle}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.workTitle}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={9}>
                                    <FormGroup>
                                        <Label for="cellPhone">
                                            Telefon
                                        </Label>
                                        <Input
                                            id="cellPhone"
                                            name="cellPhone"
                                            placeholder="Telefon"
                                            invalid={touched.cellPhone && !!errors.cellPhone}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.cellPhone}
                                        />
                                    </FormGroup>
                                </Col>
                                
                                <Col md={3}>
                                    <FormGroup>
                                        <Label for="active">
                                            Aktiv
                                        </Label>
                                        <Input
                                            type="select"
                                            name="active"
                                            id="active"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.active}>
                                            <option value="1">Ja</option>
                                            <option value="0">Nei</option>
                                        </Input>
                                    </FormGroup>
                                </Col>
                                
                                <Col md={12}>
                                    <FormGroup>
                                        <Label for="description">
                                            Kostnadsgruppe
                                        </Label>
                                        <CheckboxTree
                                            nodes={costAccountData}
                                            checked={checked}
                                            showExpandAll={true}
                                            expanded={expanded}
                                            onCheck={(nChecked, targetNode)  =>  {setChecked([targetNode.value]); console.log(targetNode)}}
                                            onExpand={(nExpanded)  => setExpanded(nExpanded)}
                                            onlyLeafCheckboxes={true}
                                            iconsClass="fa5"
                                        />
                                    </FormGroup>
                                </Col>
                                
                            </Row>
                            <Button type="submit" disabled={isSubmitting || !isValid}>
                                {isSubmitting ? 'Laster...' : values.userId !== null ? 'Lagre' : 'Opprett & send velkomstmail' }
                            </Button>
                            <br/><br/>
                        </Form>
                    )}
            </Formik>
        </div>
    )
}