import {useQuery} from "react-query";
import DataTable from 'react-data-table-component';
import http from "../../../Services/Network/http";
import {useParams} from "react-router-dom";
import {Button} from "reactstrap";
import {useNavigate} from "react-router";

interface userCredit {
    credit: number
    usedCredit: number
    periodYear: number
}
export const AdminUserCreditList = () => {
    const navigate = useNavigate();
    const { userId } = useParams();
    
    const { data: credits } = useQuery(
        "fetchUserCredit",
        () => http.get(`/User/GetCreditsForUserId/${userId}`));

    const { data: user } = useQuery(
        "fetchUser",
        () => http.get(`/User/${userId}`));

    const columns = [
        {
            name: 'Poeng gitt',
            selector: (row : userCredit) => row.credit,
        },
        {
            name: 'Poeng brukt',
            selector: (row : userCredit) => row.usedCredit,
        },
        {
            name: 'Poeng rest',
            selector: (row : userCredit) => row.credit-row.usedCredit,
        },
        {
            name: 'Periode',
            selector: (row : userCredit) => row.periodYear,
        }
    ];


    return (
        <div>
            <h2 style={{float:'left'}}>Konto &gt; Poeng &gt; {user?.fullName}</h2>
            <Button color="primary"  style={{float:'right'}} size="sm" onClick={() => navigate(`/admin/user/credit/${userId}/add`)}>Legg til poeng</Button>
            <DataTable
                columns={columns}
                data={(credits || [])}
                onRowClicked={(row : userCredit) => navigate(`/admin/user/credit/${userId}/update/${row.periodYear}`)}
                highlightOnHover={true}
            />
            
        
        </div>
    );
}