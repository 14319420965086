import {useParams} from "react-router-dom";
import {useNavigate} from "react-router";
import {useMutation, useQuery} from "react-query";
import http from "../../../Services/Network/http";
import {Formik} from "formik";
import * as Yup from "yup";
import {Button, Col, Form, FormGroup, Input, Label, Row} from "reactstrap";
import moment from "moment";

interface systemMessage {
    systemMessageId: number
    title: string
    message: string
    type: number
    expires: Date
    active: boolean
}

export const AdminSystemMessage = () => {
    const {id} = useParams();

    const navigate = useNavigate();

    const messageMutation = useMutation((messageForSaving: systemMessage) => messageForSaving.systemMessageId === null ? http.post('/System/AddMessage', messageForSaving) : http.put('/System/UpdateMessage', messageForSaving), {
        onSuccess: (data) => {
            navigate("/admin/system/messages");
        },
        onError: (error: any, variables, context) => {
            console.log(error.response.data);
        },
    });

    const {data: message} = useQuery(
        ["fetchSystemMessage", id],
        () => http.get(`/System/GetMessage/${id}`),
        {enabled: id !== undefined})


    return (
        <div>
            <Formik
                enableReinitialize={true}
                initialValues={{
                    systemMessageId: message?.systemMessageId ?? null,
                    title: message?.title ?? "",
                    message: message?.message ?? "",
                    type: message?.type ?? 1,
                    expires: message?.expires ?? null,
                    active: message?.active ?? true
                }}
                validationSchema={Yup.object({
                    title: Yup.string().required(),
                    message: Yup.string().required(),
                    type: Yup.number().required(),
                    expires: Yup.date(),
                    active: Yup.boolean()
                })}
                onSubmit={(values, {setSubmitting}) => {
                    setSubmitting(false);
                    messageMutation.mutate({
                        systemMessageId: values.systemMessageId,
                        title: values.title,
                        message: values.message,
                        type: values.type,
                        expires: values.expires,
                        active: values.active,
                    });
                }}>
                {
                    ({
                         values,
                         errors,
                         touched,
                         handleChange,
                         handleBlur,
                         isSubmitting,
                         isValid,
                         handleSubmit
                     }) => (
                        <Form onSubmit={handleSubmit} noValidate>
                            <Row>
                                <Col md={12}>
                                    <FormGroup>
                                        <Label for="title">
                                            Tittel
                                        </Label>
                                        <Input
                                            id="title"
                                            name="title"
                                            placeholder="Tittel"
                                            invalid={touched.title && !!errors.title}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.title}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <FormGroup>
                                        <Label for="message">
                                            Melding
                                        </Label>
                                        <Input
                                            type="textarea"
                                            id="message"
                                            name="message"
                                            placeholder="Melding"
                                            invalid={touched.message && !!errors.message}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.message}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <FormGroup>
                                        <Label for="type">Type</Label>
                                        <Input
                                            type="select"
                                            name="type"
                                            id="type"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.type}>
                                            <option value={1}>Informasjon</option>
                                            <option value={2}>Advarsel</option>
                                            <option value={3}>Feil</option>
                                        </Input>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <FormGroup>
                                        <Label for="expires">
                                            Utløpsdato
                                        </Label>
                                        <Input
                                            id="expires"
                                            name="expires"
                                            placeholder="Utløpsdato"
                                            type="date"
                                            invalid={touched.expires && !!errors.expires}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={moment(values.expires).format('yyyy-MM-DD')}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <FormGroup check>
                                        <Label check for="active">
                                            <Input
                                                name="active"
                                                id="active"
                                                type="checkbox"
                                                invalid={touched.active && !!errors.active}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                checked={values.active}
                                            />
                                            Aktiv?
                                        </Label>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Button type="submit" disabled={isSubmitting || !isValid}>
                                {isSubmitting ? 'Laster...' : 'Lagre'}
                            </Button>
                        </Form>
                    )}
            </Formik>
        </div>
    )
}