import LocalStorageService from "../../Services/Storage/LocalStorageService";
import {useNavigate} from "react-router";
import {Navigate} from "react-router-dom";
import React from "react";

export const Logout = () => {
    const navigate = useNavigate();
    LocalStorageService.clearToken();
    navigate('/auth/login');
    return <Navigate to="/auth/login" />;
}