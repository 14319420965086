import React, {useContext} from "react";
import Drawer from "@mui/material/Drawer";
import {ShoppingCartContext} from "../Context/ShoppingCartContext";
import styled from "styled-components";
import {Button, Col, Row} from "reactstrap";
import {TiTrash} from "react-icons/ti";
import {useNavigate} from "react-router";
import {useTranslation} from "react-i18next";


export const Wrapper = styled.aside`
  font-family: Arial, Helvetica, sans-serif;
  width: 500px;
  padding: 20px;
`;

const ShoppingCartDrawer = ({ isOpen, closeDrawer } : {isOpen: boolean, closeDrawer: Function}) => {
    const navigate = useNavigate();
    const shoppingCartContext = useContext(ShoppingCartContext);

    const [t] = useTranslation('base');
    
    return (
        <Drawer
            anchor={"right"}
            open={isOpen}
            onBackdropClick={() => closeDrawer(false)}>
            <Wrapper>
                <h3 style={{float:"left"}}>{t('shoppingCart.shoppingCart')}</h3>
                {shoppingCartContext.state.length !== 0 && <Button size={'sm'} style={{float: 'right'}} onClick={() => {closeDrawer(); navigate("/checkout")}}>{t("shoppingCart.goToCheckout")}</Button>}
                <div style={{clear:"both"}}/>
                <hr/>
                {shoppingCartContext.state.map((item, index) => (
                    <Row key={index}>
                        <Col md={2}>
                            <img alt={"product"} src={`${item.imageUrl}`} width={"100%"} style={{border: "1px solid lightgray", borderRadius: "0.25rem"}} />
                        </Col>
                        <Col md={8}>
                            <b>{item.title} - {item.variant}</b><br/>
                            {t('shoppingCart.quantity')}: {item.quantity}<br/>
                            {t('shoppingCart.price')}: {item.price}
                        </Col>
                        <Col md={2}>
                            <TiTrash onClick={() => shoppingCartContext.dispatch({ type: "remove", item })} style={{cursor: 'pointer', width: 24, height: 24, float:'right' }} />
                        </Col>
                        <Col md={12}><hr/></Col>
                    </Row>
                    
                ))}
                <h4 style={{float: 'left'}}>{t('shoppingCart.total')}: {shoppingCartContext.state.reduce((acc, item) => acc + item.quantity * item.price, 0)}</h4>
                {shoppingCartContext.state.length !== 0 && <Button size={'sm'} style={{float: 'right'}} onClick={() => {closeDrawer(); navigate("/checkout")}}>{t("shoppingCart.goToCheckout")}</Button>}
            </Wrapper>
        </Drawer>
    );
}


export default ShoppingCartDrawer;
